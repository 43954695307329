import React, { useState, useEffect } from "react";
import { createUseStyles, useTheme } from "react-jss";
// import { Row, Column } from "simple-flexbox";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Table,
  Checkbox,
  Row,
  Col,
} from "antd";
import {
  deleteRequestData,
  getRequest,
  patchRequest,
  postRequest,
} from "../../services/request";
import config from "../../services/config";
import { formatString } from "../../utils/helpers";
import { error, success } from "../../services/swal-mixin";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "70%",
    height: "100%",
    borderRadius: "1px",
    border: "1px solid gray",
    padding: "10px",
  },
  h2: {
    fontSize: "15px",
    fontWeight: "700",
  },
  canDo: {
    background: "rgba(139, 184, 159, 0.2)",
    padding: "10px",
  },
  cantDo: {
    background: "rgba(227, 211, 211, 0.2)",
    padding: "10px",
  },
  p: {
    fontSize: "6px",
    margin: "5px 0",
    color: "red",
  },
  box: {
    display: "flex",
  },
  rows: {
    width: "100%",
    gap: "10px",
    marginTop: "20px",
  },
  h4: {
    fontSize: "14px",
  },
  cols: {
    width: "48%",
  },
}));

const EditRole = ({ show = false, onCancel }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [showing, setShowing] = useState(show);
  const [form] = Form.useForm()

  useEffect(() => {
    setShowing(show);
  }, [show]);

  const [loading, setLoading] = useState(false);
  const [custom, setCustom] = useState({});
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState({});
  const [access, setAccess] = useState([]);

  const getData = async () => {
    setLoading(true);
    const response = await getRequest(
      `admin-get-roles?limit=1000`,
      config.roleUrl
    );

    setLoading(false);
    if (response.data.status == false) {
      return;
    }
    const data = response?.data?.data || {};
    setRoles(data.data);
  };

  const getRolePermission = async (roleId) => {
    setLoading(true);
    const response = await getRequest(
      `admin-get-access-and-non-access?role_id=${roleId}`,
      config.roleUrl
    );
    const roleResponse = await getRequest(
      `admin-get-role?role_id=${roleId}`,
      config.roleUrl
    );

    setLoading(false);
    if (response.data.status == false) {
      return;
    }

    const data = response?.data?.data || {};
    console.log(roleResponse?.data?.data, 'ROLE RESPONSE')
    console.log(data, 'ACCESS')
    setRole(roleResponse?.data?.data);
    setAccess(data);
  };

  const defaultValues = {
    name: role?.userRole || '',
    description: role?.description || '',
    permissions: access?.role_ability?.map(item=>item.id) || [],
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    form.setFieldsValue(defaultValues)
   }, [form, defaultValues])

  const editRole = async (e) => {
    console.log(e.permissions)
    console.log(defaultValues.permissions)
    const deleted_permissions = defaultValues.permissions.filter((item) => !e.permissions.includes(item));
    const added_permissions = e.permissions.filter((item) => !defaultValues.permissions.includes(item));
    const payload = {
      userRole: e.name,
      description: e.description,
      //permissions: e.permissions,
      role_id: e.roleId,
      deleted_permissions,
      added_permissions
    };
    const response = await patchRequest(
      "admin-update-role",
      payload,
      config.roleUrl
    );
    const { status, message } = response.data;
    if (status !== true) {
      return error(message);
    }
    success(message);
    setShowing(false);
    window.location.reload();
  };

  return (
    <Modal
      visible={showing}
      onCancel={onCancel}
      footer={false}
      title={<h4 className="text-center">Edit Role</h4>}
      initialValues={defaultValues}
    >
      <Form layout="vertical" onFinish={editRole} form={form}>
        <div className={classes.memberContainer}>
          <Form.Item label="Select Role To Edit " name="roleId">
            <Select onChange={getRolePermission} style={{ width: '100%' }}>
              {                roles?.map((item, idx) => (
              <Select.Option value={item.id} key={item.id}>{item.userRole}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Change Role Name " name="name">
            <Input placeholder="Give this role a name" />
          </Form.Item>
          <Form.Item label="Change Role Description" name="description">
            <Input placeholder="Give this role a discription" />
          </Form.Item>
        </div>
        <Form.Item name="permissions" label="Pemissions" initialValue={access?.role_ability?.map(item=>item.id)}>
          <Checkbox.Group className={classes.box} style={{ width: "100%" }}>
            <Row>
              {access?.role_ability?.map((item, idx) => (
                <Col span={12} key={idx} style={{ margin: "2px 0" }}>
                  <Checkbox value={item.id} style={{ fontSize: "10px" }}>
                    {formatString(item?.access)} 
                  </Checkbox>
                </Col>
              ))}

              {access?.role_non_able?.map((item, idx) => (
                <Col span={12} key={idx} style={{ margin: "2px 0" }}>
                  <Checkbox value={item.id} style={{ fontSize: "10px" }} >
                    {formatString(item?.access)}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item className="text-center mt-4">
          <Space>
            <Button
              size="large"
              htmlType="submit"
              type="ghost"
              className="rounded px-5"
            >
              Edit Role
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditRole;
