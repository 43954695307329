import React, { Suspense } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import LoadingComponent from '../components/loading';
import SLUGS from '../resources/slugs';
import PromotionsComponent from '../views/promotions';
import WayaGramOfficialComponent from '../views/tech/WayaGramOfficialComponent';
import { ManagePollsComponent } from '../views/management';
import ManageMomentsComponent from '../views/management/Moment';
import AdvertsComponent from '../views/promotions/Adverts';
import ManageDonationsComponent from '../views/management/ManageDonationsComponent';
import ManageEventComponent from '../views/management/ManageEvents';
import ManageInterestsComponent from '../views/management/ManageInterestsComponent';
import ManagePagesComponent from '../views/management/ManagePages/Page';
import ManagePostsComponent from '../views/management/ManagePost';
import GroupFormComponent from '../views/management/GroupAccounts/index';
import WayaAnalytics from '../views/management/WayaGramAnalytics/Analytics';
import HashtagComponent from '../views/management/Hashtag';
import Live from '../views/management/WayagramLive/Live';
import Vote from '../views/management/Votes/Votes';
import WayaUsers from '../views/management/WayagramUsers';
import FriendUsers from '../views/management/ManageAdverts/AdertsTable';
import WayaSimulatio from '../views/management/simulatedWayagram/overview';
import WayaSimulatedUsers from '../views/management/simulatedWayagram/simulatedUsers.';
import PostComponent from '../views/management/WayagramUsers/posts';
import LikePageComponent from '../views/management/WayagramUsers/likedPage';
import Page404 from './404Page';
import Product from '../views/product';
import PaymentRequests from '../views/paymentRequest';
import HashtagDetails from '../views/management/Hashtag/HashtagDetails';
import GroupPosts from '../views/management/GroupAccounts/GroupPosts';
import ManagePricing from '../views/management/ManagePricing';
import EditPricing from '../views/management/ManagePricing/EditPricing';
import AddPricing from '../views/management/ManagePricing/AddPricing';
import ManagePromotion from '../views/management/ManagePromotion';
import Logs from '../views/management/Logs';
import Account from '../views/management/Logs/Account';
import ReportLog from '../views/management/Logs/ReportLog';
import EmailManagement from '../views/management/EmailManagement';
import PushNotification from '../views/management/PushNotification';
import SmsManagement from '../views/management/SmsManagement';
import ActivityLog from '../views/management/Logs/ActivityLog';
import WayaAccount from '../views/management/Logs/WayaAccount';
import Feedback from '../views/management/Logs/Feedback';
import WayaCall from '../views/management/WayaCall';
import WayagramLive from '../views/management/WayagramLive';
import CRM from '../views/management/CRM';
import Chat from '../views/management/Chat';
import RolesAndAccess from '../views/management/RolesAndAccess';
import ManageAdmin from '../views/management/manageAdmin';
import RequestLog from '../views/management/Logs/RequestLog';
// import BusinessReport from '../views/management/BusinessReport';
import Groups from '../views/management/BusinessReport/Groups';
import Pages from '../views/management/BusinessReport/Pages';
import Polls from '../views/management/BusinessReport/Polls';
import Donations from '../views/management/BusinessReport/Donations';
import Events from '../views/management/BusinessReport/Events';
import Request from '../views/management/BusinessReport/Request';
import PaymentRequest from '../views/management/BusinessReport/PaymentRequest';
import PaymentDisburments from '../views/management/BusinessReport/PaymentDisburment';
import Sms from '../views/management/BusinessReport/Sms';
import Email from '../views/management/BusinessReport/Email';
import Notification from '../views/management/BusinessReport/Notification';
import ManageHosts from '../views/contest/manageHost';
import ContestSettings from '../views/contest/settings';
import Contests from '../views/contest/contest';
import ManageCategories from '../views/product/categories';
import Flyers from '../views/product/flyers';
import Disbursement from '../views/management/Logs/disbursement';
import ContestDetails from '../views/contest/contestDetails';
import Referrals from '../views/referrals';
import Statistics from '../views/contest/statistics';
import CV from '../views/management/CV';
import ProductOrders from '../views/product/orders';













function PrivateRoutes() {
  return (
    <Suspense fallback={<LoadingComponent loading />}>
      <Routes>
        <Route
          exact
          path={SLUGS.manage_posts}
          element={<ManagePostsComponent />}
        />
        <Route
          exact
          path={SLUGS.manage_polls}
          element={<ManagePollsComponent />}
        />
        <Route
          exact
          path={SLUGS.manage_moments}
          element={<ManageMomentsComponent />}
        />
        <Route exact path={SLUGS.promotions} element={<PromotionsComponent />} />
        <Route exact path={SLUGS.product} element={<Product />} />
        <Route exact path={SLUGS.categories} element={<ManageCategories />} />
        <Route exact path={SLUGS.orders} element={<ProductOrders />} />
        <Route exact path={SLUGS.flyers} element={<Flyers />} />

        <Route
          exact
          path={SLUGS.manage_interests}
          element={<ManageInterestsComponent />}
        />
        <Route
          exact
          path={SLUGS.manage_events}
          element={<ManageEventComponent />}
        />
        <Route
          exact
          path={SLUGS.manage_pages}
          element={<ManagePagesComponent />}
        />
        <Route exact path={SLUGS.promotions} element={<PromotionsComponent />} />
        <Route exact path={SLUGS.adverts} element={<AdvertsComponent />} />
        <Route
          exact
          path={SLUGS.manage_donations}
          element={<ManageDonationsComponent />}
        />
        <Route
          exact
          path={SLUGS.tech_waya_official}
          element={<WayaGramOfficialComponent />}
        />
        <Route
          exact
          path={SLUGS.group_accounts}
          element={<GroupFormComponent />}
        />
        <Route
          exact
          path={SLUGS.group_posts}
          element={<GroupPosts />}
        />
        <Route exact path={SLUGS.waya_analytics} element={<WayaAnalytics />} />
        <Route exact path={SLUGS.hashtag} element={<HashtagComponent />} />
        <Route exact path={SLUGS.hashtagDetails} element={<HashtagDetails />} />
        {/* <Route exact path={SLUGS.live} element={<Live />} /> */}
        <Route exact path={SLUGS.vote} element={<Vote />} />
        <Route exact path={SLUGS.waya_users} element={<WayaUsers />} />
        <Route exact path={SLUGS.waya_friends} element={<FriendUsers />} />
        <Route exact path={SLUGS.waya_simu} element={<WayaSimulatio />} />
        <Route
          exact
          path={SLUGS.waya_simulated_users}
          element={<WayaSimulatedUsers />}
        />
        <Route exact path={SLUGS.waya_post} element={<PostComponent />} />
        <Route exact path={SLUGS.contest_stats} element={<Statistics />} />
        <Route exact path={SLUGS.manage_contests} element={<Contests />} />
        <Route exact path={SLUGS.contest_details} element={<ContestDetails />} />
        <Route exact path={SLUGS.manage_hosts} element={<ManageHosts />} />
        <Route exact path={SLUGS.contest_settings} element={<ContestSettings />} />

        <Route
          exact
          path={SLUGS.waya_like_page}
          element={<LikePageComponent />}
        />
        <Route
          exact
          path={SLUGS.manage_pricing}
          element={<ManagePricing />}
        />
        <Route
          exact
          path={SLUGS.edit_pricing}
          element={<EditPricing />}
        />
        <Route
          exact
          path={SLUGS.add_pricing}
          element={<AddPricing />}
        />
        <Route
          exact
          path={SLUGS.manage_promotion}
          element={<ManagePromotion />}
        />
        <Route
          exact
          path={SLUGS.manage_referrals}
          element={<Referrals />}
        />
        <Route
          exact
          path={SLUGS.logs}
          element={<Logs />}
        />
        <Route
          exact
          path={SLUGS.account}
          element={<WayaAccount />}
        />
        <Route
          exact
          path={SLUGS.waya_account}
          element={<Account />}
        />
        <Route
          exact
          path={SLUGS.report_log}
          element={<ReportLog />}
        />
        <Route
          exact
          path={SLUGS.activity_log}
          element={<ActivityLog />}
        />
        <Route
          exact
          path={SLUGS.feedback}
          element={<Feedback/>}
        />
        <Route
          exact
          path={SLUGS.request_log}
          element={<RequestLog />}
        />
        <Route
          exact
          path={SLUGS.email_management}
          element={<EmailManagement />}
        />
         <Route
          exact
          path={SLUGS.push_notifications}
          element={<PushNotification/>}
        />
        <Route
          exact
          path={SLUGS.sms_management}
          element={<SmsManagement />}
        />
        <Route
          exact
          path={SLUGS.waya_call}
          element={<WayaCall />}
        />
         <Route
          exact
          path={SLUGS.wayagram_live}
          element={<WayagramLive />}
        />
         <Route
          exact
          path={SLUGS.CRM}
          element={<CRM />}
        />
        <Route
          exact
          path={SLUGS.CV}
          element={<CV />}
        />
         <Route
          exact
          path={SLUGS.chat}
          element={<Chat />}
        />
         <Route
          exact
          path={SLUGS.roles_and_access}
          element={<RolesAndAccess/>}
        />
         <Route
          exact
          path={SLUGS.roles_access}
          element={<RolesAndAccess/>}
        />
        <Route
          exact
          path={SLUGS.manage_admin}
          element={<ManageAdmin/>}
        />
         {/* <Route
          exact
          path={SLUGS.business_report}
          element={<BusinessReport/>}
        /> */}
         <Route
          exact
          path={SLUGS.groups}
          element={<Groups />}
        />
         <Route
          exact
          path={SLUGS.pages}
          element={<Pages/>}
        />
         <Route
          exact
          path={SLUGS.polls}
          element={<Polls/>}
        />
         <Route
          exact
          path={SLUGS.donations}
          element={<Donations/>}
        />
         <Route
          exact
          path={SLUGS.events}
          element={<Events/>}
        />
         <Route
          exact
          path={SLUGS.payment_request}
          element={<PaymentRequest/>}
        />
        <Route
          exact
          path={SLUGS.disbursement}
          element={<Disbursement/>}
        />
         <Route
          exact
          path={SLUGS.payment_disburment}
          element={<PaymentDisburments/>}
        />
         <Route
          exact
          path={SLUGS.request}
          element={<Request/>}
        />
         <Route
          exact
          path={SLUGS.sms}
          element={<Sms/>}
        />
         <Route
          exact
          path={SLUGS.email}
          element={<Email/>}
        />
         <Route
          exact
          path={SLUGS.push}
          element={<Notification/>}
        />
        

        <Route exact path={SLUGS.page404} element={<Page404 />} />
        <Route exact path="*" element={<Navigate to={SLUGS.waya_analytics} />} />
      </Routes>
    </Suspense>
  );
}

export default PrivateRoutes;
