import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import { Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { postRequest } from "../../../services/request";
import { error, success } from "../../../services/swal-mixin";
import "antd/dist/antd.css";
import { DatePicker, Space, Modal } from "antd";
import moment from "moment";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: "25px 0",
    display: "flex",
    justifyContent: "space-around",
  },
  tabContent2: {
    padding: "25px 0",

    justifyContent: "space-around",
  },

  link: {
    marginLeft: 40,
  },
  textme: {
    minWidth: "92%",
    marginBottom: "10px",
  },
  textme2: {
    minWidth: "250%",
  },
  btnp3: {
    marginLeft: 585,
    marginBottom: 20,
    backgroundColor: "#FF4B01",
    color: "white",
    marginTop: 30,
    "@media (max-width: 768px)": {
      marginLeft: 14,
    },
  },
}));

const BulkUserComponent = (props) => {
  const classes = useStyles();

  const {
    getWayaUsers,
    handlCloseSingleUser,
    handleOpenSingleUser,
    mode,
    show,
  } = props;
  const [value, setValue] = React.useState(0);
  const state = {
    numberOfProfiles: "",
  };
  const [bool, setBool] = React.useState(state);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const handleViewClose = () => {
    handlCloseSingleUser();
  };
  const handleViewOpen = () => {
    handleOpenSingleUser();
  };

  const handleChanges = (e, newValue) => {
    const { value, name } = e.target;
    setBool((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const createUsers = async () => {
    if (bool.numberOfProfiles === "" || bool.numberOfProfiles === "0") {
      return error("no value entered/invalid value, please enter value");
    }
    const formdata = new FormData();
    formdata.append("file", value);

    const response = await postRequest(
      `admin-profile/wayagram-number-bulk-profile-creation`,
      { numberOfProfiles: bool.numberOfProfiles },
      process.env.REACT_APP_BASE_URL + "/wayagram-service/main"
    );

    const { data, status, message } = response?.data;

    if (status === false) {
      return error(message);
    }

    if (data) {
      success(message);
    } else {
      return error("Error! please select a correct value and file");
    }
    handleViewClose();
    setValue("");
    setBool(state);
    getWayaUsers();
  };

  const deleteUsers = async () => {};

  const handleSubmit = async () => {
    if (mode === "delete") {
      return deleteUsers();
    } else {
      await createUsers();
    }
  };

  return (
    <div className={classes.root}>
      <Modal
        title={`${mode === "delete" ? "Delete" : "Create"} Simulated Users`}
        onCancel={handleViewClose}
        visible={show}
        footer={[
          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => handleSubmit()}
          >
            {mode === "delete" ? "Delete" : "Create"} Users
          </Button>,
        ]}
      >
        <Space direction="horizontal" hidden={mode !== "delete"}>
          <DatePicker onChange={(a, b) => console.log(a, b)} />
          <DatePicker onChange={(a, b) => console.log(a, b)} />
        </Space>
        <TextField
          className={classes.textme}
          id="outlined-select-currency-native"
          value={bool.numberOfProfiles}
          name="numberOfProfiles"
          onChange={handleChanges}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          hidden={mode === "delete"}
        ></TextField>
      </Modal>
      {/* <Modal
        show={handleViewOpen}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ marginTop: 150, minWidth: "100%" }}
      >
        <Modal.Header
          style={{
            borderBottom: "none",
            padding: "0 1rem",
            borderTop: "5px solid #FF6634",
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.tabContent}>
            <div className={classes.name2}>
              <Typography variant="h6">
              </Typography>
            </div>
          </div>
          <div className={classes.tabContent2}>
            <div className={classes.link}>
              {mode === "delete" ? (
                <Space direction="horizontal">
                  <DatePicker defaultvalue={moment()} onChange={(a,b)=>console.log(a)} />
                  <DatePicker defaultvalue={moment()} onChange={(a,b)=>setEndDate(a)} />
                </Space>
              ) : (
                <TextField
                  className={classes.textme}
                  id="outlined-select-currency-native"
                  value={bool.numberOfProfiles}
                  name="numberOfProfiles"
                  onChange={handleChanges}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                ></TextField>
              )}
            </div>
          </div>
          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => handleSubmit()}
          >
            {mode === "delete" ? "Delete" : "Create"} Users
          </Button>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default BulkUserComponent;
