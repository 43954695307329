import config from "./config";
import { getRequest } from "./request";

const setToken = ({ token }) => {
  console.log("setToken")
  localStorage.setItem('token', token);
};

const setUserData = (data) => {
  // console.log(data)
  localStorage.setItem('user', JSON.stringify(data.user));
};

const setSpecificUserData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const getToken = () => {
  return localStorage.getItem('token');
};

const getUserData = () => {
  return JSON.parse(localStorage.getItem('user') ?? '{}');
};

const getSpecificUserData = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

const emailValidator = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const numberValidator = (number) => {
  const re = /[+-]?([0-9]*[.])?[0-9]+/;
  return re.test(String(number));
};

const getFullName = (user) =>
  user.name
    ? user.name
    : user.customer_name
    ? user.ReportersName
    : user.ReportersName
    ? user.customer_name
    : user.user
    ? `${user.user.firstName} ${user.user.lastName || user.user.surname}`
    : `${user.firstName} ${user.lastName || user.surname}`;

const clearStorage = () => localStorage.clear();
const addLight = function (color, amount) {
  let cc = parseInt(color, 16) + amount;
  let c = cc > 255 ? 255 : cc;
  c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
  return c;
};

/* const hexToRGB = (color) => {
  color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
  let colorRGB = [color.substring(0,2)]
} */

const subtractLight = function (color, amount) {
  let cc = parseInt(color, 16) - amount;
  let c = cc < 0 ? 0 : cc;
  c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`;
  return c;
};

const lightenColor = (color, amount) => {
  color = color.indexOf('#') >= 0 ? color.substring(1, color.length) : color;
  amount = parseInt((255 * amount) / 100);
  return (color = `#${addLight(color.substring(0, 2), amount)}${addLight(
    color.substring(2, 4),
    amount
  )}${addLight(color.substring(4, 6), amount)}`);
};

const darkenColor = (color, amount) => {
  color = color.indexOf('#') >= 0 ? color.substring(1, color.length) : color;
  amount = parseInt((255 * amount) / 100);
  return (color = `#${subtractLight(
    color.substring(0, 2),
    amount
  )}${subtractLight(color.substring(2, 4), amount)}${subtractLight(
    color.substring(4, 6),
    amount
  )}`);
};

const formatPhoneNumber = (phone) => {
  const phoneNum = phone
  if (phoneNum?.startsWith('234') || phoneNum?.startsWith('+234') || phoneNum === '') {
    return phoneNum;
  } else {
    let start = "+234"
    const phoneNumber = phoneNum?.substring(1);
    return `${start}${phoneNumber}`;
  }
};

function currencyFormat(num) {
  return '₦' + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const getUsers = async (q, setUsers) => {
  const response = await getRequest(
    `admin-profile/search?query=${q}&page=1`,
    config.mainUrl
  );
  const { data, status } = response?.data || {};
  if(status) {
    setUsers(data || []);
  }
};

const getPageCategories = async (setCategories) => {
  const response = await getRequest(
    `page/get-all-page-categories?page=1&limit=100`,
    config.mainUrl
  );
  const { interests, status } = response?.data || {};
  if(status && interests.data) {
    setCategories([...interests.data, { title: 'others' }] || []);
  }
};

const sumObjectData = (obj) => {
  const data = Object.values(obj)
  return data.reduce((a,b)=>Number(a)+Number(b))
}

export {
  setToken,
  getToken,
  setUserData,
  setSpecificUserData,
  getUserData,
  getSpecificUserData,
  emailValidator,
  numberValidator,
  getFullName,
  clearStorage,
  lightenColor,
  darkenColor,
  formatPhoneNumber,
  currencyFormat,
  getUsers,
  getPageCategories,
  sumObjectData,
};
