import React from "react";

import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { Formik } from "formik";

import { putRequest } from "../../../services/request";
import { success } from "../../../services/swal-mixin";

const status = [
  {
    value: false,
    label: "Public",
  },
  {
    value: true,
    label: "Not public",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    // background: theme.color.wayaPink,
    background: "#FF6634",
    color: "#ffffff",
    // padding: '8px 16px',
    "&:hover": {
      background: "#2D9CDB",
    },
  },
  cardContent: {
    padding: 15,
  },
}));

const UpdateWayaUserComponent = ({ data, className, reload, hideModal, ...rest }) => {
  const classes = useStyles();

  // const getRoles = async () => {
  //   const response = await getRequest(
  //     'role-service/all',
  //     'https://46.101.41.187:8061'
  //   );
  //   // const { data, status, message } = response;

  //   if (status === false) {
  //   return
  //     // swalService.error(message);
  //   }
  // };

  // useEffect(() => {
  //   getRoles();
  // }, []);

  return (
    <Formik
      initialValues={{
        notPublic: data.notPublic,
        displayName: data.displayName,
        username: data.username,
        user_id: data.UserId,
        // avatar: "",
        //coverImage: "",
      }}
      validationSchema={Yup.object().shape({
        displayName: Yup.string().max(255).required("First name is required"),
        username: Yup.string().max(255).required("Last name is required"),
        notPublic: Yup.boolean().required("user type is required"),

        // user_id: Yup.string().max(255).required('passwordis required'),
        //admin: Yup.string().test('please choose a user type', (value)=> value === false || value === true),
      })}
      onSubmit={async (data) => {
        // if(data.admin ==="true"){
        //   data.admin = true
        // }
        // if(data.admin === "false"){
        //   data.admin = false
        // }
        const formdata = new FormData();
        // formdata.append("file", data.avatar);
        // formdata.append("file", data.coverImage);

        const response = await putRequest(
          "admin-profile/update",
          data,
          process.env.REACT_APP_BASE_URL + "/wayagram-service/main"
        );

        // data = ''
        success(response.data.message);
        reload();
        hideModal();
        // window.location.reload();
        // navigate('/app/dashboard', { replace: true });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader="Update user Wayagram profile."
              title="Update user wayagram profile"
            />
            <Divider />
            <CardContent className={classes.cardContent}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Select User Type"
                    name="notPublic"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.notPublic}
                    variant="outlined"
                    error={Boolean(touched.notPublic && errors.notPublic)}
                    helperText={touched.notPublic && errors.notPublic}
                  >
                    {status.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="Username"
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                    value={values.username}
                    key={1}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Display name"
                    name="displayName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.displayName && errors.displayName)}
                    helperText={touched.displayName && errors.displayName}
                    required
                    value={values.displayName}
                    key={2}
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid item md={6} xs={12}>
                  <p>Avatar Image</p>
                  <TextField
                    fullWidth
                    // label="Avatar Image"
                    name="avatar"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="file"
                    error={Boolean(touched.avatar && errors.avatar)}
                    helperText={touched.avatar && errors.avatar}
                    required
                    value={values.avatar}
                    key={3}
                    variant="outlined"
                  />
                </Grid> */}
                {/* <Grid item md={6} xs={12}>
                  <p>Cover Image</p>
                  <TextField
                    fullWidth
                    // label="Cover Image"
                    type="file"
                    name="coverImage"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.coverImage && errors.coverImage)}
                    helperText={touched.coverImage && errors.coverImage}
                    value={values.coverImage}
                    key={4}
                    variant="outlined"
                    required
                  />
                </Grid> */}
              </Grid>
            </CardContent>
            {/* <Divider /> */}
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                className={classes.createButton}
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Update
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

UpdateWayaUserComponent.propTypes = {
  className: PropTypes.string,
};

export default UpdateWayaUserComponent;
