import React, { useEffect, useState } from 'react';
import { getRequest, postRequest, patchRequest, deleteRequestData } from '../../services/request';
import CsvDownload from 'react-json-to-csv';
import LoadingComponent from '../../components/loading/LoadingComponent'
import config from '../../services/config';
import { Button, Form, Input, Modal, notification, Pagination, Select, Table } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { donationColumn } from '../../utils/dummyData';
import SearchBar from '../../components/SearchBar';
import DonationProgress from '../../components/DonationProgress';
import { getUsers } from '../../services/helpers';
import StatCard from '../../components/StatCard';


const ManageDonationsComponent = ({ createButton=false, showRange = false, showSearch = true  }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [metaData, setMetaData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [viewModal, setViewModal] = useState(false)
  const [viewData, setViewData] = useState({})
  const [pages, setPages] = useState(1);
  const [filterType, setFilterType] = useState("username");
  const [limits, setLimits] = useState(20);
  const [images, setImages] = useState([]);
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState("a");
  const [createPostModal, setcreatePostModal] = useState(false);
  const [mode, setMode] = useState("");
  const { confirm } = Modal;
  const [form] = Form.useForm()
  const amountRaised = viewData?.Donate?.reduce((sum, amt) => sum+= amt?.amount, 0)
  const [query, setQuery] = useState("")
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [page, setPage] = useState(1);
  const selectOptions = [
    {
      value: "username",
      label: "Username"
    },
    {
      value: "email",
      label: "Email"
    },
    {
      value: "phone",
      label: "Phone"
    },
  ]

  const handleChange = (e) => {
    const {value} = e.target
    if (value){
      setQuery(value);
    }
  };
  const [range, setRange] = useState({
    startDate: '',
    endDate: '',
  });

  const handleSubmitPost = async(values) =>{
    setLoading(true);
    const newForm = new FormData();
    newForm.append('profile_id', values.profile_id);
    mode === 'update' && newForm.append('donation_id', values.donation_id );
    newForm.append('donationImage', images);
    newForm.append('description', values.description);
    newForm.append('ownerDescription', values.ownerDescription);
    newForm.append('impact', values.impact);
    newForm.append('title', values.title);
    newForm.append('estimatedAmount', values.estimatedAmount);
    newForm.append('minimumAmount', values.minimumAmount);
    newForm.append('amountJustification', values.amountJustification);
    newForm.append('displayTotalDonation', values.displayTotalDonation);
    newForm.append('displayNumberOfDonors', values.displayNumberOfDonors);
    newForm.append('donationExpiresAt', values.donationExpiresAt);
    const url = `admin-${mode}-donation`;
    let  res = {}
    if (mode === 'create') {
      res = await postRequest(url, newForm, config.postUrl);
    }else{
      res = await patchRequest(url, newForm, config.postUrl);
    }
    const {status, data, message} = res?.data || {}
    if (status) {
      notification.success({description:message, message:'success!'});
      setcreatePostModal(false)
    } else {
      notification.error({description:message, message:'Oops!'});
    }
    setLoading(false);
    setFilteredPosts(data?.data || data || []);
  };

  const handleManage = async (action, id, profile_id) => {
    const payload = {
      action,
      donation_id: id,
      profile_id,
      
    }
    setLoading(true);
    const res = await patchRequest(
      `admin-manage-donation`,
      payload,
      config.postUrl
    );
    const {status, message} = res?.data || {}
    if (status) {
      notification.success({description:message, message:'success!'});
    } else {
      notification.error({description:message, message:'Oops!'});
    }
    getData();
  }

  const getData = async (q) => {
    let response;
    // if(q){
    //   response = await getRequest(
    //     `/admin-search-donations?page=${pages}&limit=${limits}&query=${q}&filterBy=${filterType}`,
    //     config.postUrl
    //     );
    // }else{
      let rangeQuery='';
      const filter = q ? `&${filterType}=${q}` : '';
      if (range.startDate && range.endDate) {
        rangeQuery = `&startDate=${range.startDate}&endDate=${range.endDate}`;
      // }
      setLoading(true)
      response = await getRequest(
        `admin-get-all-donations?page=${pages}&limit=${limits}${rangeQuery}${filter}`,
        config.postUrl
      );
    }
    response = await getRequest(
      `admin-get-all-donations?page=${pages}&limit=${limits}`,
      config.postUrl
    );
    const {data, metadata} = response?.data?.data || {};
    setMetaData(metadata?.total || 0)
    setFilteredData(data)
    setFilteredPosts(data?.data || data || []);
    setData(data);
    setLoading(false)
  };
 
  const handleConfirm = (status, id, pid) =>{
    confirm({
      title: `Do you want to ${status} this donation?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Click OK to Continue',
      onOk() {
        if(status === 'close') deActivate(id, pid)
        else handleManage(status, id, pid)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const deActivate = async (id, pid) => {
    const datas = {
      donation_id: id
    };
    setLoading(true)
    const response = await deleteRequestData(
      `admin-close-donation?profile_id=${pid}`,
      datas,
      config.postUrl
    );
    const { status, message } = response?.data || {};
    if (status) {
      notification.success({message, description:"donation deleted"})
    }else{
      notification.warning({message, description:"delete failed"})
    }
    getData();
  };
  const fetchUsers = async (q, page) => {
    let response;
    if (q) {
      response = await getRequest(
        `admin-profile/all?page=1&username=${q}`,
        config.mainUrl
      );
    }
    if (!response || response.error !== undefined) {
      return;
    }
    setUsers(response?.data?.data?.AllWayAGramProfiles);
    console.log({response: response?.data?.data?.AllWayAGramProfiles});
  };

  useEffect(() => {
    fetchUsers(value, setUsers);
  }, [value]);

  useEffect(() => {
    getData(query);
  }, [pages, query, range]);

  useEffect(() => {
    getData();
  }, []);

  const Conversion = () => {
    return (
      <CsvDownload
        data={data}
        filename="donations.csv"
        style={{
          //pass other props, like styles
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',
          backgroundColor: '#27AE60',
          borderRadius: '6px',
          // border: '1px solid #a511c0',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '15px',
          // marginTop: '16px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
          // textShadow: '0px 1px 0px #9b14b3',
        }}
      >
        Export Donations
      </CsvDownload>
    );
  };

  const handleModals=(type, value)=>{
    if(type==='newPost'){
      setcreatePostModal(true)
      setMode("create")
    }
    if(type==='editPost'){
      setcreatePostModal(true)
      setMode("update")
      form.setFieldsValue({
        description:value.description,
        ownerDescription:value.ownerDescription,
        title:value.title,
        impact:value.impact,
        estimatedAmount:value.estimatedAmount,
        minimumAmount:value.minimumAmount,
        amountJustification:value.amountJustification,
        displayNumberOfDonors:value.displayNumberOfDonors,
        displayTotalDonation:value.displayTotalDonation,
        donationExpiresAt: new Date(value.donationExpiresAt).toLocaleDateString(),
        profile_id: value.ProfileId,
        donation_id: value.id
      })
    }
    if(type==='view'){
      setViewModal(true)
      setViewData(value)
    }
  }

  const handlePreview = (e) => {
    const { files } = e.target;
    setImages(files[0])
  };

  const column = donationColumn(handleModals, handleConfirm)
  return (
    <div className="w-100">
      <SearchBar
        page="donation"
        total={metaData}
        handleModals={handleModals}
        Conversion={Conversion}
        handleChange={handleChange}
        setFilterType={setFilterType}
        selectOptions={selectOptions}
        createButton={createButton}
        showRange={showRange}
        showSearch={showSearch}
        setRange={setRange}

      />
      <LoadingComponent loading={loading} />
      <Table scroll={{ x: 1500 }} columns={column} dataSource={data} rowKey="id" loading={loading} pagination={false} />
      <Pagination
        total={metaData}
        responsive={true}
        defaultCurrent={1}
        onChange={(e)=>setPages(e)}
        defaultPageSize={limits}
        showSizeChanger={false}
      />
      <Modal
        visible={createPostModal}
        onCancel={()=> setcreatePostModal(false)}
        footer={false}
        title={<p className='text-center'>Create New Donation</p>}
      >
        <Form layout='vertical' onFinish={handleSubmitPost} form={form}>
          <Form.Item label="Images">
            <div className="input-group">
              <input type="file"
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                onChange={handlePreview}
                accept="image/*"
                aria-label="Upload" />
              {/* <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">Browse</button> */}
            </div>
          </Form.Item>
          <Form.Item label="Post Owner" name="profile_id" hidden={mode === "update"}>
            <Select
              showSearch
              value={value}
              placeholder="Search for user"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(e) => setValue(e)}
              notFoundContent={null}
            >
              {users.map(user =>
                <Select.Option key={user.id}>{user.displayName}</Select.Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item label="Title" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label=" Owner Description" name="OwnerDescription">
            <Input.TextArea />
          </Form.Item>
          <Form.Item hidden name="donation_id">
            <Input />
          </Form.Item>
          <Form.Item label="Estimated Amount" name="estimatedAmount">
            <Input type="number"  min="1"/>
          </Form.Item>
          <Form.Item label="Minimum Amount" name="minimumAmount">
            <Input type="number"  min="1"/>
          </Form.Item>
          <Form.Item label="Amount justification" name="amountJustification">
            <Input type="number"  min="1"/>
          </Form.Item>
          <Form.Item label="impact" name="impact">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Expires Date" name="donationExpiresAt">
            <Input type="date" />
          </Form.Item>
          <Form.Item label="Display Total Donation" name="displayTotalDonation">
            <Select placeholder="" className='w-100'>
              <Select.Option key={true}>True</Select.Option>
              <Select.Option key={false}>False</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Display Number Of Donors" name="displayNumberOfDonors">
            <Select placeholder="" className='w-100'>
              <Select.Option key={true}>True</Select.Option>
              <Select.Option key={false}>False</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item className='text-center mt-4'>
            <Button size='large' loading={loading} htmlType='submit' type='primary' className='rounded px-5'>
              {mode.toUpperCase()} DONATION
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={viewModal}
        onCancel={()=> setViewModal(false)}
        footer={false}
        width={655}
        height={447}
        title=""
      >
        <div className="row mt-2">
          <StatCard
            title="Total Number of Donators"
            value={viewData?.Donate?.length}
            type="amount"
          />
          <StatCard
            title="Total Amount Received"
            value={amountRaised ?? 0}
            type="amount"
          />
          <StatCard
            title="Time Remaining"
            value={viewData?.donationExpiresAt}
            type="date"
          />
        </div>
        <DonationProgress
          description={viewData?.description}
          current={amountRaised || 0}
          total={viewData?.estimatedAmount}
          title=""
        />
        <h5 className="progress-bar-text">
          {amountRaised ?? 0} Raised out of{' '}
          {viewData.estimatedAmount}
        </h5>
      </Modal>
    </div>
  );
};

export default ManageDonationsComponent;
