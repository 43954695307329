import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@material-ui/icons/Edit";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { getRequest, putRequest, setHeader } from "../../../services/request";
import { success, error } from "../../../services/swal-mixin";
import moment from "moment";
import { Link } from "react-router-dom";
import SLUGS from "../../../resources/slugs";
import WayaUpdate from "./wayaUpdate";
import Followers from "./followersModal";
import Following from "./followingModal";
import store from "../../../redux";
import Groups from "./groups";
import { Row } from "simple-flexbox";
import { CsvConversion } from "../../../components/CsvDownload";
import MiniCardComponent from "../../../components/cards/MiniCardComponents";
import SearchBar from "../../../components/SearchBar";
import config from "../../../services/config";
import { Pagination } from "antd";
import CsvDownload from "react-json-to-csv";
import { getUserData } from "../../../services/helpers";
import { Progress } from 'antd';
import UserProfile from "./userProfile";


const useStyle = makeStyles((theme) => ({
  table: {
    minWidth: "100vw",
  },
  root: {
    width: "100%",
    display: "flex",
  },
  tool: {
    paddingLeft: "0px",
    borderRadius: "250px",
  },
  text: {
    width: "150%",
    fontWeight: "800px",
    borderRadius: "1px red",

    "& .MuiFormLabel-root": {
      border: "none",
      color: "black",
      marginLeft: 5,
    },
  },
  textfield: {
    paddingTop: "5px",
    paddingRight: "100px",
    marginRight: "580px",
    marginBottom: "50px",
  },
  cardsContainer: {
    marginLeft: -30,
    marginTop: -30,
    "@media (max-width: 768px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  cardRow: {
    marginTop: 30,
    "@media (max-width: 768px)": {
      marginTop: 0,
    },
  },
  miniCardContainer: {
    flexGrow: 1,
    marginRight: 20,
    marginLeft: 30,
    marginBottom: 20,
    paddingLeft: "5px",
    borderRadius: "15px",
    "@media (max-width: 768px)": {
      maxWidth: "none",
    },
  },
  progressContainer: {
    marginBottom: 20,
  }
}));

export default function WayaTable({ handleModals, progress, fetchUsers, setTotal }) {
  const classes = useStyle();
  const [page, setPage] = React.useState(0);
  
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [wayaShow, setWayaShow] = useState(false);
  const [values, setValues] = useState("");
  const [allUsers, setAllUsers] = useState(0);

  const [value, setValue] = useState([]);
  const [friend, setFriend] = useState(false);
  const [groupShow, setGroupShow] = useState(false);
  const [follow, setFollow] = useState(false);
  const [val, setVal] = useState([]);
  const [vals, setVals] = useState([]);
  const [active, setActive] = useState(0);
  const [inactive, setInActive] = useState(0);
  const [metaData, setMetaData] = useState({});
  const [filterType, setFilterType] = useState("description");
  const userData = getUserData();
  const [userCanManage, setUserCanManage] = useState(false);
  const [facts, setFacts] = useState([]);
  const [listening, setListening] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [showProfile, setShowProfile] = useState(false);

  const handleChange = (e) => {
    setValues(e.target.value);
  };
  const handleCloseFriends = (key) => {
    setFriend(false);
  };
  const handleOpenFriends = (key) => {
    // console.log(key);
    const val = users.find((user) => user.UserId === key);
    setVal(val);
    setFriend(true);
  };
  const handleCloseGroups = (key) => {
    setGroupShow(false);
  };
  // const handleOpenGroups = (key) => {
  //   const val = users.find((user) => user.UserId === key);
  //   setVal(val);
  //   setGroupShow(true);
  // };
  const handleCloseFollow = () => {
    setFollow(false);
  };
  const handleOpenFollow = (key) => {
    const val = users.find((user) => user.UserId === key);
    setVals(val);
    setFollow(true);
  };

  const handleOpenWayaUser = (key) => {
    const val = users.find((user) => user.UserId === key);
    setValue(val);
    setWayaShow(true);
  };
  const handleOpenProfile = (row) => {
    //  console.log({ row }); 
    setSelectedUser(row)
    setShowProfile(true);
    // const val = users.find((user) => user.UserId === key);
    // setValue(val);
    // setWayaShow(true);
  };
  const handleCloseWayaUser = () => {
    setWayaShow(false);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const getWayaUsers = async (q) => {
    let response = await fetchUsers(q, page);
    var { data } = response.data;
    setUsers(data?.AllWayAGramProfiles || data || []);
    setAllUsers(data?.totalNumberOfWayaGramProfiles);
    setActive(data?.activeWayaGramProfiles);
    setInActive(data?.nonActiveWayaGramProfiles);
    setTotal(data?.totalNumberOfWayaGramProfiles || data?.length);
    setMetaData({
      all: data?.totalNumberOfWayaGramProfiles || data?.length,
      active: data?.activeWayaGramProfiles,
      inactive: data?.nonActiveWayaGramProfiles,
    });
    if (data?.totalNumberOfWayaGramProfiles || data?.length) {
      response = await getRequest(
        `admin-profile/all?page=${1}&limit=${
          data?.totalNumberOfWayaGramProfiles || data?.length
        }`,
        config.mainUrl
      );
      let res = response?.data?.data;
      setCsvData(res?.AllWayAGramProfiles || data || []);
    }
  };

  const activateWayaUsers = async (id, deactivate) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("profile_id", id);
    formData.append("deactivate", !deactivate);
    const response = await putRequest(
      "admin-profile/activate-or-deactivate-wayagram-account",
      formData,
      config.mainUrl
    );

    setLoading(false);
    const { data } = response || {};
    if (!response || response.error !== undefined || data?.status !== true) {
      return error(response.data.message);
    }

    success(response.data.message);
    getWayaUsers(values);
  };


  useEffect(()=> {
    if(progress) {
      getWayaUsers(values);
    }
  }, [progress])
  // useEffect(() => {
  //   if (!listening) {
  //     const events = new EventSourcePolyfill(`${config.mainUrl}/admin-profile/simulated-users?sso_id=${userData?.id}`, setHeader);
  //     events.onmessage = (event) => {
  //       console.log('IN EVENT', event);
  //       const parsedData = JSON.parse(event.data);
  //       setFacts((facts) => facts.concat(parsedData));
  //     };
  //     setListening(true);
  //   }
  //   console.log('OUT EVENT', listening, facts);
  // }, [listening, facts]);

  useEffect(() => {
    setLoading(true);
    getWayaUsers(values);
    setLoading(false);
  }, [page, values]);

  // const postUpdate = (user) => {
  //   store.dispatch({
  //     type: "POST_UPDATE",
  //     payload: user,
  //   });
  // };

  // const RenderStat = () => {
  //   return (
  //     <Row
  //       className={classes.cardsContainer}
  //       wrap
  //       flexGrow={1}
  //       horizontal="space-between"
  //       breakpoints={{ 768: "column" }}
  //     >
  //       <LoadingComponent loading={loading} />
  //       <Row
  //         className={classes.cardRow}
  //         wrap
  //         flexGrow={1}
  //         horizontal="space-between"
  //         breakpoints={{ 384: "column" }}
  //       >
  //         <MiniCardComponent
  //           className={classes.miniCardContainer}
  //           title="Total Number of Users"
  //           value={allUsers}
  //           // icon={IconUsers}
  //           iconColor="#F2C94C"
  //         />
  //         <MiniCardComponent
  //           className={classes.miniCardContainer}
  //           title="Total Active users"
  //           value={active}
  //           // icon={IconUsers}
  //           iconColor="#27AE60"
  //         />
  //         <MiniCardComponent
  //           className={classes.miniCardContainer}
  //           title="Total Inactive Users"
  //           value={inactive}
  //           // icon={IconUsers}
  //           iconColor="red"
  //         />
  //       </Row>
  //     </Row>
  //   );
  // };

  useEffect(() => {
    const getUserRole = async () => {
      const response = await getRequest(`get-user-data?user_id=${userData?.id}`, config.roleUrl);
      if (response.data.status) {
        const filter = response?.data?.data?.Role?.RoleAccess?.filter((r) => r?.Permission?.access === "CAN_MANAGE_USER")
        setUserCanManage(filter?.length);
      }
    }
    getUserRole();
  }, []);

  return (
    <>
      <div className={classes.root}>
        <div className="w-100">
          <SearchBar
            page="user"
            total={metaData}
            handleModals={handleModals}
            hideSelect={true}
            createButton={!userCanManage}
            placeholder="Search by Username"
            Conversion={() => (
              <CsvDownload
                data={csvData}
                filename="wayagram_Users.csv"
                style={{
                  boxShadow: "inset 0px 1px 0px 0px #e184f3",
                  backgroundColor: "#27AE60",
                  borderRadius: "5px",
                  display: "inline-block",
                  cursor: "pointer",
                  color: "#ffffff",
                  fontSize: "18px",
                  fontWeight: "bold",
                  padding: "6px 24px",
                  textDecoration: "none",
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Export Users
              </CsvDownload>
            )}
            handleChange={handleChange}
            setFilterType={setFilterType}
          />
          <LoadingComponent loading={loading} />
          <div className={classes.progressContainer} hidden={progress === 0}>
            <Progress percent={progress} status="active" strokeColor={{ '0%': '#108ee9', '100%': '#87d068' }}  />
          </div>
          {/* 
          <TextField
            className={classes.text}
            id="outlined-basic"
            label="Search Users by Name"
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
            onChange={handleChange}
          />
        */}
        </div>

        {groupShow ? (
          <Groups user={val} handleCloseGroups={handleCloseGroups} />
        ) : null}
      </div>
      {wayaShow ? (
        <WayaUpdate
          userId={value}
          wayaShow={wayaShow}
          handleCloseWayaUser={handleCloseWayaUser}
          getWayaUsers={getWayaUsers}
        />
      ) : null}
      {/* <RenderStat /> */}
      <TableContainer component={Paper} style={{ overflow: "scroll" }}>
        <Table className={classes.table} aria-label="simple table" style={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow style={{ backgroundColor: "#fbe2dd", color: "#E7472C" }}>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: '150px' }}
              >
                User ID
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: '200px' }}
              >
                Name
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: '200px' }}
              >
                Username
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: '300px' }}
              >
                Email
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: '200px' }}
              >
                Phone
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: '130px' }}
                align="left"
              >
                Wayagram
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: '150px' }}
                align="left"
              >
                Followers
              </TableCell>
              {/* <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px" }}
                align="right"
              >
                WAYAGRAM'S
              </TableCell> */}
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: '150px' }}
                align="left"
              >
                Followings
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: '100px' }}
                align="left"
              >
                Posts
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: '100px' }}
                align="left"
              >
                Groups
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: '100px' }}
                align="left"
              >
                Pages
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: '150px' }}
                align="left"
              >
                Date
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px", width: "300px" }}
                align="center"
              >
                Action
              </TableCell>

            </TableRow>
          </TableHead>

          <TableBody>
            {(users || [])
              // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" style={{wordWrap: 'break-word'}}>
                    {row.UserId}
                  </TableCell>
                  <TableCell component="th" scope="row" style={{wordWrap: 'break-word'}}>
                    {row.displayName ? row.displayName : "N/A"}
                  </TableCell>
                  <TableCell component="th" scope="row" style={{wordWrap: 'break-word'}}>
                    {row.username ? row.username : "N/A"}
                  </TableCell>
                  <TableCell component="th" scope="row" style={{wordWrap: 'break-word'}}>
                    {row.email ? row.email : "N/A"}
                  </TableCell>
                  <TableCell component="th" scope="row" style={{wordWrap: 'break-word'}}>
                    {row.phone ? row.phone : "N/A"}
                  </TableCell>
                  <TableCell component="th" scope="row" style={{wordWrap: 'break-word'}}>
                    {row.simulatedUser ? "simulated" : "non-simulated"}
                  </TableCell>
                  <TableCell align="left" style={{wordWrap: 'break-word'}}>
                    {row.followers ? row.followers : 0}
                    <Button
                      style={{
                        marginLeft: ".4rem",
                        fontSize: "7px",
                        backgroundColor: "#fdf1ec",
                        color: "#ed462f",
                        border: "1px solid #ed462f",
                      }}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleOpenFriends(row.UserId)}
                    >
                      View
                    </Button>
                  </TableCell>
                  <TableCell align="left" style={{wordWrap: 'break-word'}}>
                    {row.followings ? row.followings : 0}
                    <Button
                      style={{
                        marginLeft: ".4rem",
                        fontSize: "7px",
                        backgroundColor: "#fdf1ec",
                        color: "#ed462f",
                        border: "1px solid #ed462f",
                      }}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleOpenFollow(row.UserId)}
                    >
                      View
                    </Button>
                  </TableCell>
                  <TableCell component="th" scope="row" style={{wordWrap: 'break-word'}}>
                    {row.posts}
                  </TableCell>
                  {/* <Link to={SLUGS.waya_post}>
                    <TableCell align="right">
                      {row.postCount ? row.postCount : 0}
                      <Button
                        style={{
                          marginLeft: ".4rem",
                          fontSize: "7px",
                          backgroundColor: "#fdf1ec",
                          color: "#ed462f",
                          border: "1px solid #ed462f",
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={() => postUpdate(row.id)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </Link> */}

                  {/* <TableCell align="right">
                    <Button
                      style={{
                        fontSize: "7px",
                        backgroundColor: "#fdf1ec",
                        color: "#ed462f",
                        border: "1px solid #ed462f",
                      }}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleOpenGroups(row.UserId)}
                    >
                      View
                    </Button>
                  </TableCell> */}
                  <TableCell component="th" scope="row" style={{wordWrap: 'break-word'}}>
                    {row.pageCount}
                  </TableCell>
                  {/* <Link to={SLUGS.waya_like_page}>
                    <TableCell align="right">
                      {row.postCount ? row.postCount : 0}
                      <Button
                        style={{
                          marginLeft: ".4rem",
                          fontSize: "7px",
                          backgroundColor: "#fdf1ec",
                          color: "#ed462f",
                          border: "1px solid #ed462f",
                        }}
                        variant="outlined"
                        color="primary"
                        onClick={() => postUpdate(row.id)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </Link> */}
                  <TableCell component="th" scope="row" style={{wordWrap: 'break-word'}}>
                    {row.groupCount}
                  </TableCell>
                  <TableCell align="left" style={{wordWrap: 'break-word'}}>
                    {moment(row.createdAt).format("MMM, Do YYYY")}
                  </TableCell>
                  <TableCell style={{wordWrap: 'break-word'}}>
                    <EditIcon onClick={() => handleOpenWayaUser(row.UserId)} />
                    {/* </TableCell>
                    <TableCell align="center" style={{ color: "red" }}> */}
                    {row.isDisabled === false ? (
                      <Button
                        variant="outlined"
                        style={{
                          backgroundColor: "#fdf1ec",
                          color: "red",
                          fontSize: "8px",
                        }}
                        onClick={() =>
                          activateWayaUsers(row.id, row.isDisabled)
                        }
                      >
                        Deactivate
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        style={{
                          backgroundColor: "#dcfbeb",
                          color: "green",
                          fontSize: "10px",
                        }}
                        onClick={() =>
                          activateWayaUsers(row.id, row.isDisabled)
                        }
                      >
                        Activate
                      </Button>
                    )}
                    <Button
                      style={{
                        marginLeft: ".4rem",
                        fontSize: "7px",
                        backgroundColor: "#fdf1ec",
                        color: "#ed462f",
                        border: "1px solid #ed462f",
                      }}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleOpenProfile(row)}
                    >
                      View Profile
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {users?.length === 0 && (
            <Box>
              No data found.
            </Box>
          )}
        {friend ? (
          <Followers
            user={val}
            handleOpenFriends={handleOpenFriends}
            handleCloseFriends={handleCloseFriends}
          />
        ) : null}
        {showProfile ? (
          <UserProfile
            showModal={showProfile}
            hideModal={setShowProfile}
            data={selectedUser}
          />
        ) : (
          ''
        )}
        {/* <TablePagination
          style={{ backgroundColor: "#fbe2dd", color: "#E7472C" }}
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          component="div"
          count={allUsers}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
        <Pagination
          total={allUsers}
          responsive={true}
          defaultCurrent={1}
          onChange={(e) => setPage(e)}
          defaultPageSize={20}
          showSizeChanger={false}
        />
      </TableContainer>
      {follow ? (
        <Following
          user={vals}
          handleOpenFollow={handleOpenFollow}
          handleCloseFollow={handleCloseFollow}
        />
      ) : null}
    </>
  );
}
