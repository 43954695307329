const slugs = {
  // private
  dashboard: '/dashboard',
  users: '/users/all',
  manage_team: '/users/manage_team/:id',
  manage: '/users/manage',
  manage_roles: '/users/manage_roles',
  private_users: '/users/private_users',
  admin: '/users/admin',
  wallet_user: '/users/wallet_user',
  web_pos: '/users/web_pos',
  terminal_pos: '/users/terminal_pos',
  business_type: '/users/businessType',
  merchants: '/users/mechants',
  agents: '/users/agents',
  referral: '/users/referral',
  ref_user: '/users/ref_user',
  temporary_users: '/users/temporary_users',
  paystack_transactions: '/transactions/paystack_transactions',
  rubies_transactions: '/transactions/rubies_transactions',
  pos_transactions: '/transactions/pos_transactions',
  refunds: '/transactions/refunds',
  wallet: '/transactions/wallet',
  transactions: '/transactions',
  payment: '/transactions/payment',
  charge_fee: '/transactions/charge_fee',
  billers: '/transactions/billers',
  bills_pay: '/transactions/bills_pay',
  disputes: '/transactions/disputes',
  withdrawal: '/transactions/withdrawal',
  non_waya_transfer: '/transactions/non_waya_transfer',
  biller_info: '/transactions/biller_info',
  logs: '/logs',
  act_log: '/logs/act_log',
  qr_codes: '/qr_codes',
  otp_codes: '/otp_codes',
  marketing: '/marketing',
  chat: '/marketing/chat',
  call: '/marketing/call',
  wayagram_analytics: '/marketing/wayagram_analytics',
  bulk_sms: '/marketing/bulk_sms',
  sms_not: '/marketing/bulk_sms/sms_not',
  push_notifications: '/marketing/push_notifications',
  email: '/marketing/email',
  promotions: '/promotions',
  adverts: '/promotions/adverts',
  tech: '/tech',
  overview_tech: '/overview_tech',
  api_key: '/api_key',
  agre_bill: '/agre_bill',
  tech_admin: '/tech_admin',
  tech_waya_official: '/tech/waya_officical',
  crm: '/tech/crm',
  reciepts: '/tech/receipts',
  waya_transact: '/tech/waya_transact',
  mass_funding_waya: '/tech/mass_funding_waya',
  mass_funding_non_waya: '/tech/mass_funding_non_waya',
  mass_debit_non_waya: '/tech/mass_debit_non_waya',
  waya_official: '/waya_official',
  waya_activities: '/waya_official/activities',
  waya_followers: '/waya_official/followers',
  management: '/management',
  waya_analytics: '/',
  manage_posts: '/management/manage_posts',
  manage_moments: '/management/manage_moments',
  manage_interests: '/management/manage_interests',
  group_accounts: '/management/group_accounts',
  group_posts: '/management/:type/posts/:id',
  manage_pages: '/management/manage_pages',
  manage_events: '/management/manage_events',
  manage_polls: '/management/manage_polls',
  waya_users: '/management/waya_users',
  //payment_request: '/management/payment_request',
  // live: '/management/live',
  vote: '/management/vote',
  terms: '/settings/terms_condition',
  FAQ: '/settings/FAQ',
  privacy: '/settings/privacyPolicy',
  manage_donations: '/management/donations',
  hashtag: '/management/hashtag',
  hashtagDetails: '/management/hashtag/users/:id',
  manage_roles_perm: '/manage_roles_perm',
  corp: '/thirdparty/corp',
  corp_mem: '/thirdparty/corp_mem',
  corp_main: '/thirdparty/corp_main',
  edit_profile: '/edit_profile',
  generate_users: '/generate_users',
  waya_friends: '/waya_friends',
  report: '/report',
  dispute: '/dispute',
  feedback: '/feedback',
  waya_logs: '/waya_logs',
  hashtagpost: '/hashtagpost',
  kyc: '/kyc',
  kyc_docs: '/kyc_docs',
  kyc_settings: '/kyc_settings',
  export: '/export',
  waya_comm: '/waya_commission',
  waya_simu: '/waya_simulated',
  waya_post: '/waya_post',
  product: '/product_post',
  waya_like_page: '/waya_like_page',
  transaction_charge: '/transaction_charge',
  waya_simulated_users: '/waya_simulated_users',
  waya_simulated_page: '/waya_simulated_page',
  waya_simulated_group: '/waya_simulated_group',
  manage_pricing:'/manage_pricing',
  edit_pricing: '/edit_pricing',
  add_pricing: '/add_pricing',
  manage_promotion: '/manage_promotion',
  manage_referrals: '/manage_referrals',
  logs:'/logs',
  account:'/logs/account',
  request_log:'/logs/request_log',
  report_log:'/logs/report_log',
  activity_log:'/logs/activity_log',
  waya_account:'/logs/waya_account',
  feedback:'/logs/feedback',
  email_management: '/email_management',
  push_notifications: '/push_notifications',
  sms_management: '/sms_management',
  waya_call: '/waya_call',
  wayagram_live: '/wayagram_live',
  CRM: '/CRM',
  CV: 'content_visibility',
  chat: '/chat',
  roles_and_access: '/roles_and_access/',
  roles_access: '/roles_and_access/:id',
  manage_admin:'/manage_admin',
  business_report:'/business_report',
  groups:'/business_report/groups',
  pages:'/business_report/pages',
  donations:'/business_report/donations',
  polls:'/business_report/polls',
  events:'/business_report/events',
  payment_request:'/business_report/payment_request',
  payment_disburment:'/business_report/payment_disburment',
  request:'/business_report/request',
  email:'/business_report/email',
  sms:'/business_report/sms',
  push:'/business_report/push',
  manage_hosts: '/contest/manage_hosts',
  manage_contests: '/contest/manage_contests',
  contest_stats: '/contest/contest_statistics',
  contest_settings: '/contest/settings',
  contest_details: '/contest/details/:id',
  categories: '/products/categories',
  flyers: '/products/flyers',
  orders: '/products/orders',
  disbursement: '/logs/disbursement',







  




  
  // auth
  login: '/login',
  verify_otp: '/verify_otp',
  forgotPassword: '/forgot_password',
  changePasswordFromEmail: '/forgot-password',
  // wrong url
  page404: '/',
};
export default slugs;
