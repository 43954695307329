import { Card, Table } from 'antd';
import React from 'react';
import chartistPluginTooltip from 'chartist-plugin-tooltips-updated'
import ChartistGraph from 'react-chartist'
import {MdGroups, MdOutlinePersonAddAlt} from 'react-icons/md'
import {FcMoneyTransfer} from 'react-icons/fc'


export function AnalyticsCard({post, share, like, group, comment, followers, followings}) {
  return (
    <div className='w-100'>
      {/* <div className='w-100'>
        <div className='text-end'>
          <button className='btn btn-success'>Export Analysis</button>
        </div>
      </div> */}
      <div className='w-100 my-4'>
        <Card  className='shadow text-center'>
          <div className='row'>
            <div className='col-md-1'/>
            <div className='my-3 col-12 col-sm-6 col-md-2'>
              <MdOutlinePersonAddAlt size={35} color="#FF4400" />
              <p className='mb-0 mt-3 fs-4 fw-bold'>{followers}</p>
              <span className='text-secondary' style={{fontSize: '10px'}}>TOTAL WAYAGRAM OFFICIAL FOLLOWERS</span>
            </div>
            <div className='my-3 col-12 col-sm-6 col-md-2'>
              <MdGroups size={35} color="#FF4400"/>
              <p className='mb-0 mt-3 fs-4 fw-bold'>{followings}</p>
              <span className='text-secondary' style={{fontSize: '10px'}}>TOTAL WAYAGRAM OFFICIAL FOLLOWING</span>
            </div>
            <div className='my-3 col-12 col-sm-6 col-md-2'>
              <FcMoneyTransfer size={35} color="#FF4400"/>
              <p className='mb-0 mt-3 fs-4 fw-bold'>{followings}</p>
              <span className='text-secondary' style={{fontSize: '10px'}}>TOTAL WAYAGRAM PERCENTAGE COMISSION</span>
            </div>
          </div>
          

          <div className='row'>
            <div className='col-md-1'/>
            <div className='my-3 col-12 col-sm-6 col-md-2'>
              <img alt='...' src='/assets/icons/post.svg' />
              <p className='mb-0 mt-3 fs-4 fw-bold'>{post}</p>
              <span className='text-uppercase fs-6 text-secondary'>posts</span>
            </div>
            <div className='my-3 col-12 col-sm-6 col-md-2'>
              <img alt='...' src='/assets/icons/like.svg' />
              <p className='mb-0 mt-3 fs-4 fw-bold'>{like}</p>
              <span className='text-uppercase fs-6 text-secondary'>like</span>
            </div>
            <div className='my-3 col-12 col-sm-6 col-md-2'>
              <img alt='...' src='/assets/icons/share.svg' />
              <p className='mb-0 mt-3 fs-4 fw-bold'>{share}</p>
              <span className='text-uppercase fs-6 text-secondary'>shares</span>
            </div>
            <div className='my-3 col-12 col-sm-6 col-md-2'>
              <img alt='...' src='/assets/icons/group.svg' />
              <p className='mb-0 mt-3 fs-4 fw-bold'>{group}</p>
              <span className='text-uppercase fs-6 text-secondary'>groups</span>
            </div>
            <div className='my-3 col-12 col-sm-6 col-md-2'>
              <img alt='...' src='/assets/icons/comment.svg' />
              <p className='mb-0 mt-3 fs-4 fw-bold'>{comment}</p>
              <span className='text-uppercase fs-6 text-secondary'>comments</span>
            </div>
            <div className='col-md-1'/>
          </div>
        </Card>
      </div>
    </div>
  )
}


export function TableCard({title, columns, datas}) {
  return (
    <div className='h-100'>
      <div className='my-2 h-100'>
        <p className='m-0 fs-5 fw-bold'>{title}</p>
      </div>
      <div className='rounded-5 bg-white p-1 h-100'>
        <div className='h-100'>
          <Table className='rounded-5 h-100' columns={columns} dataSource={datas} pagination={false} rowKey="id" />
        </div>
      </div>
    </div>
  )
}


export function LayCard({title, children}) {
  return (
    <div className='h-100'>
      <div className='my-2 '>
        <p className='m-0 fs-5 fw-bold'>{title}</p>
      </div>
      <div className='rounded-5 bg-white py-4 '>
        {children}
      </div>
    </div>
  )
}

export function PieChart() {
  const simplePieData = {
    series: [5, 3, 4],
  }
    
  const simplePieSum = function sum(a, b) {
    return a + b
  }
  
  const simplePieOptions = {
    labelInterpolationFnc(value) {
      return `${Math.round((value / simplePieData.series.reduce(simplePieSum)) * 100)}%`
    },
    plugins: [chartistPluginTooltip({ anchorToPoint: false, appendToBody: true, seriesName: false })],
  }
  return (
    <div>
      <div className="mb-5">
        <ChartistGraph
          className="height-300"
          data={simplePieData}
          options={simplePieOptions}
          type="Pie"
        />
      </div>
    </div>
  )
}



