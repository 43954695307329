import React, { useState } from "react";
import config from "../../../services/config";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { Modal, Form, Button, notification, Upload, Input } from "antd";
import { getRequest, postRequest, putRequest } from "../../../services/request";

import { useNavigate } from "react-router-dom";
import WayaTable from "./wayagramTable";
import { InboxOutlined } from "@ant-design/icons";
import { hideLoader, showLoader } from "../../../utils/loader";

const DashboardComponent = () => {
  const [loading, setLoading] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [createPostModal, setcreatePostModal] = useState(false);
  const [createManyPostModal, setcreateManyPostModal] = useState(false);
  const [createSimulatedPostModal, setcreateSimulatedPostModal] =
    useState(false);
  const [pageFollowersModal, setpageFollowersModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [total, setTotal] = useState(0);
  const [mode, setMode] = useState("");
  const [reload, setReload] = useState(false);
  const [username, setUserName] = useState("");
  const [excelFile, setExcelFile] = useState("");
  const [form] = Form.useForm();
  const { Dragger } = Upload;
  const push = useNavigate();

  const handleModals = (type, value) => {
    if (type === "single") {
      setcreatePostModal(true);
      setMode("single");
    }
    if (type === "many") {
      setcreateManyPostModal(true);
      setMode("many");
    }
    if (type === "simulated") {
      setcreateSimulatedPostModal(true);
      setMode("simulated");
    }
    if (type === "followers" || type === "following") {
      setpageFollowersModal(true);
      getFollowers(value.UserId, type);
      setUserName(`${value.username} ${type}`);
    }
    if (type === "view-post") {
      push(`/management/user/posts/${value}`);
    }
  };

  const getFollowers = async (id, req) => {
    setFollowers([]);
    setLoading(true);
    const response = await getRequest(
      `admin-friend/${req}?user_id=${id}&page=1`,
      config.graphUrl
    );
    const { data, status } = response?.data || [];
    console.log(data);
    if (status) {
      setFollowers(data.followers || data.following || []);
    }
    setLoading(false);
  };

  const fetchUsers = async (q, page) => {
    let response;
    if (q) {
      // response = await getRequest(
      //   `admin-profile/search?query=${q}&page=${page + 1}`,
      //   config.mainUrl
      // );
      response = await getRequest(
        `admin-profile/all?page=1&username=${q}`,
        config.mainUrl
      );
    } else {
      setLoading(true);
      response = await getRequest(
        `admin-profile/all?page=${page + 1}`,
        config.mainUrl
      );
    }
    if (!response || response.error !== undefined) {
      setLoading(false);
      return;
    }
    if (response.status) {
      setLoading(false);
    }
    return response;
  };

  const handleSubmitPost = async (values) => {
    setLoading(true);
    let newForm = new FormData();
    let url;
    if (mode === "single") {
      url = `admin-profile/wayagram-excel-bulk-profile-creation`;
      return;
    }
    if (mode === "many") {
      newForm.append("excelProfileRecords", excelFile);
      url = `admin-profile/wayagram-excel-bulk-profile-creation`;
    }
    if (mode === "simulated") {
      newForm = { numberOfProfiles: values.numberOfProfiles };
      url = `admin-profile/wayagram-number-queue-profile-creation`;
      setProgress(1);
      let prg = 0;
      let currenTotal = total;
      const interval = setInterval(async (e) => {
        setProgress((prg += 10));
        // const response = await fetchUsers();
        // let { data } = response.data;
        // currenTotal = data?.totalNumberOfWayaGramProfiles || data?.length;
        // prg = (currenTotal - total) * (values.numberOfProfiles / 1);
        if (prg >= 99) {
          clearInterval(interval);
          setProgress(0);
        }
      }, 2000);
    }

    const res = await postRequest(url, newForm, config.mainUrl);
    const { status, data, message } = res?.data || {};
    if (status) {
      notification.success({ description: message, message: "success!" });
      setcreatePostModal(false);
      setcreateManyPostModal(false);
      setcreateSimulatedPostModal(false);
      setReload(!reload);
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };

  const props = {
    name: "file",
    multiple: false,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        setExcelFile(info.file.originFileObj);
        console.log(info.file);
      } else if (status === "error") {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files[0]);
    },
  };

  return (
    <div className="w-100">
      <LoadingComponent loading={loading} />
      <WayaTable
        handleModals={handleModals}
        progress={progress}
        setTotal={setTotal}
        fetchUsers={fetchUsers}
      />

      {/* create single user */}
      <Modal
        visible={createPostModal}
        onCancel={() => setcreatePostModal(false)}
        footer={false}
        title={<p className="text-center">Create New User</p>}
      >
        <Form layout="vertical" onFinish={handleSubmitPost} form={form}>
          <Form.Item className="text-center mt-4">
            <Button
              size="large"
              loading={loading}
              htmlType="submit"
              type="primary"
              className="rounded px-5"
            >
              Create User
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* upload excel */}
      <Modal
        visible={createManyPostModal}
        onCancel={() => setcreateManyPostModal(false)}
        footer={false}
        title={<p className="text-center">Upload Excel Users</p>}
      >
        <Form layout="vertical" onFinish={handleSubmitPost} form={form}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </Dragger>
          <Form.Item className="text-center mt-4">
            <Button
              size="large"
              loading={loading}
              htmlType="submit"
              type="primary"
              className="rounded px-5"
            >
              Create Users
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* simulated users */}
      <Modal
        visible={createSimulatedPostModal}
        onCancel={() => setcreateSimulatedPostModal(false)}
        footer={false}
        title={<p className="text-center">Create Simulated Users</p>}
      >
        <Form layout="vertical" onFinish={handleSubmitPost} form={form}>
          <Form.Item name="numberOfProfiles">
            <Input type="number" />
          </Form.Item>
          <Form.Item className="text-center mt-4">
            <Button
              size="large"
              loading={loading}
              htmlType="submit"
              type="primary"
              className="rounded px-5"
            >
              Create Users
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DashboardComponent;
