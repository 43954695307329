import axios from "axios";
import { error } from "./swal-mixin";
import { hideLoader } from "../utils/loader";

// let baseUrl = 'https://api.dev.waya-group.com';
let baseUrl = "https://services.staging.mywayapay.com";

const wayagramUrl = process.env.REACT_APP_BASE_URL + "/wayagram-service";
const authUrl = process.env.REACT_APP_AUTH_URL + "/auth-service";
const kycUrl = "http://46.101.41.187:8070";
const cardUrl = "http://157.230.223.54:3020";
const contactUrl = "http://46.101.41.187:8065";
const billPaymentUrl = "http://157.230.223.54:8181";
const walletUrl = "https://services.staging.mywayapay.com/temporal-service";
const ussdUrl = "http://157.230.223.54:4040";
const paymentRequestUrl = "http://157.230.223.54:9300/payment-request-service";
const accountCreationUrl = "http://46.101.41.187:7090";
const roleUrl = "https://services.staging.mywayapay.com/role-service";
const withdrawalUrl = "https://api.dev.waya-group.com/waya-withdrawal-service";
const disputeUrl = "http://157.230.223.54:8086/api/v1";
const generateUssdUrl = "http://http://157.245.84.14:9060/";
const socketUrl = "http://157.245.84.14:6006";
const logUrl = "http://46.101.41.187:8083";
const wayapayDispute = `http://46.101.41.187:9400/complaint-service`;

export const customBaseUrl = {
  wayagramUrl,
  authUrl,
  kycUrl,
  cardUrl,
  contactUrl,
  billPaymentUrl,
  ussdUrl,
  walletUrl,
  paymentRequestUrl,
  accountCreationUrl,
  roleUrl,
  withdrawalUrl,
  disputeUrl,
  generateUssdUrl,
  socketUrl,
  logUrl,
  wayapayDispute,
};

if (process.env.REACT_APP_NODE_ENV === "development") {
  baseUrl = "http://127.0.0.1:8080";
}
const CLIENT_ID = 'WAYAGRAM';
const CLIENT_TYPE = 'ADMIN';
export const httpPost = async (url, postBody, otherUrl, isNotAuth) => {
  if (!navigator.onLine) {
    return error("Please check your internet", "Oops!", 3000);
  }
  try {
    const res = await axios.post(
      `${otherUrl || baseUrl}${url}`,
      postBody,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              "content-type": "application/json",
              "CLIENT-TYPE": CLIENT_TYPE,
              "CLIENT-ID": CLIENT_ID,
            },
          }
        : {
            headers: {
              "content-type": "application/json",
              "CLIENT-TYPE": CLIENT_TYPE,
              "CLIENT-ID": CLIENT_ID,
            },
          }
    );
    return res.data;
  } catch (error) {
    hideLoader();
    if (error?.response?.data?.error === "Internal Server Error") {
      return {
        status: false,
        message: error.response.data.error,
      };
    }
    if (error?.response?.data?.message === "Validation Errors") {
      Object.values(error.response.data.data).map((item) =>
        error(item, "Oops!", 5000)
      );
      return {
        status: false,
        message: error.response?.data.data[0],
      };
    }
    return error?.response?.data;
  }
};

export const httpPostUnreloaded = async (
  url,
  postBody,
  otherUrl,
  isNotAuth
) => {
  if (!navigator.onLine) {
    return error("Please check your internet", "Oops!", 3000);
  }
  try {
    const res = await axios.post(
      `${otherUrl || baseUrl}${url}`,
      postBody,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              "Content-Type": "application/x-www-form-urlencoded",
              "CLIENT-TYPE": CLIENT_TYPE,
              "CLIENT-ID": CLIENT_ID,
            },
          }
        : {}
    );
    // console.log(res);
    return res.data;
  } catch (error) {
    hideLoader();
    return error.response?.data;
  }
};

export const httpPostFormData = async (url, postBody, otherUrl, isNotAuth) => {
  if (!navigator.onLine) {
    return error("Please check your internet", "Oops!", 3000);
  }
  try {
    const res = await axios.post(
      `${otherUrl || baseUrl}${url}`,
      postBody,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              "Content-Type": "multipart/form-data",
              "CLIENT-TYPE": CLIENT_TYPE,
              "CLIENT-ID": CLIENT_ID,
            },
          }
        : {}
    );
    // console.log(res);
    return res.data;
  } catch (error) {
    hideLoader();
    return error.response?.data;
  }
};

export const httpGet = async (url, otherUrl, isNotAuth) => {
  if (!navigator.onLine) {
    return error("Please check your internet", "Oops!", 3000);
  }
  try {
    const res = await axios.get(
      `${otherUrl || baseUrl}${url}`,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              "CLIENT-TYPE": CLIENT_TYPE,
              "CLIENT-ID": CLIENT_ID,
            },
          }
        : {
            headers: {
              "content-type": "application/json",
              "CLIENT-TYPE": CLIENT_TYPE,
              "CLIENT-ID": CLIENT_ID,
            },
          }
    );
    // console.log(res);
    return res.data;
  } catch (err) {
    hideLoader();
    if (err?.response?.data?.message === "Validation Errors") {
      Object.values(err?.response?.data?.data).map((item) => error(item));
      return {
        status: false,
        message: err?.response?.data.data[0],
      };
    }
    return err?.response?.data;
  }
};

export const httpPut = async (url, postBody, otherUrl, isNotAuth) => {
  if (!navigator.onLine) {
    return error("Please check your internet", "Oops!", 3000);
  }
  try {
    const res = await axios.put(
      `${otherUrl || baseUrl}${url}`,
      postBody,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              "CLIENT-TYPE": CLIENT_TYPE,
              "CLIENT-ID": CLIENT_ID,
            },
          }
        : {}
    );
    // console.log(res);
    return res.data;
  } catch (error) {
    hideLoader();
    if (error.response.data.message === "Validation Errors") {
      return {
        status: false,
        message: error.response?.data.data[0],
      };
    }
    return error.response?.data;
  }
};

export const httpPatch = async (url, postBody, otherUrl, isNotAuth) => {
  if (!navigator.onLine) {
    return error("Please check your internet", "Oops!", 3000);
  }
  try {
    const res = await axios.patch(
      `${otherUrl || baseUrl}${url}`,
      postBody,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              "CLIENT-TYPE": CLIENT_TYPE,
              "CLIENT-ID": CLIENT_ID,
            },
          }
        : {}
    );
    return res.data;
  } catch (error) {
    return error.response?.data;
  }
};

export const httpDelete = async (url, data, otherUrl, isNotAuth) => {
  if (!navigator.onLine) {
    return error("Please check your internet", "Oops!", 3000);
  }
  try {
    const res = await axios.delete(
      `${otherUrl || baseUrl}${url}`,
      !isNotAuth
        ? {
            headers: {
              Authorization: `${localStorage.token}`,
              "CLIENT-TYPE": CLIENT_TYPE,
              "CLIENT-ID": CLIENT_ID,
            },
            data,
          }
        : {}
    );
    // console.log(res);
    return res.data;
  } catch (error) {
    hideLoader();
    return error.response?.data;
  }
};
