import CsvDownload from "react-json-to-csv";
import React, { useEffect, useState } from "react";
import config from "../../../services/config";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import {
  Table,
  Select,
  Input,
  Modal,
  Form,
  Button,
  notification,
  Pagination,
} from "antd";
import {
  deleteRequestData,
  getRequest,
  patchRequest,
  postRequest,
} from "../../../services/request";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { crmColumn } from "../../../utils/dummyData";
import SearchBar from "../../../components/SearchBar";
import { getUsers } from "../../../services/helpers";
import ImagePreview from "../../../components/ImagePreview";
import { retrieveFileType } from "../../../utils/helpers";

const CRM = () => {
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState("username");
  const [query, setQuery] = useState("");
  const [metaData, setMetaData] = useState(0);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [createPostModal, setcreatePostModal] = useState(false);
  const [imageModal, setShowImageModal] = useState(false);
  const [mode, setMode] = useState("");
  const [value, setValue] = useState("a");
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const [total, setTotal] = useState(0);

  const selectOptions = [
    {
      value: "username",
      label: "Username",
    },
    {
      value: "email",
      label: "Email",
    },
  ];

  const getData = async (q) => {
    let response;
    if (q) {
      response = await getRequest(
        `admin-profile/search-media-files?query=${query}&filterBy=${filterType}&page=${pages}&limit=${limits}`,
        config.mainUrl
      );
    } else {
      setLoading(true);
      response = await getRequest(
        `admin-profile/get-all-files?page=${pages}&limit=${limits}`,
        config.mainUrl
      );
    }
    setLoading(false);
    if (response.data.status == false) {
      return;
    }
    const data = response?.data?.data || {};
    const { metadata } = response?.data?.data || {};
    console.log(data);
    setTotal(metadata?.total);
    setPosts(data?.data || data || []);
    setFilteredPosts(data?.data?.data || []);
  };

  const getCRMOverview = async () => {
    const response = await getRequest("admin-get-crm-stats", config.socialUrl);
    const { data } = response?.data || {};
    setMetaData(data);
  };

  const deActivate = async (id) => {
    const datas = {
      fileId: id,
    };
    setLoading(true);
    const response = await deleteRequestData(
      "delete-file-upload",
      datas,
      config.socialUrl
    );
    setLoading(false);

    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message,
        description: "deleted",
      });
      getData();
    } else {
      notification.warning({
        message,
        description: "error",
      });
    }
  };

  const handleConfirm = (d) => {
    confirm({
      title: `Do you want to delete these item?`,
      icon: <ExclamationCircleOutlined />,
      content: "Click OK to Continue",
      onOk() {
        deActivate(d);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    getData(query);
  }, [pages, query]);

  useEffect(() => {
    getUsers(value, setUsers);
  }, [value]);

  useEffect(() => {
    getCRMOverview();
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    if (value) {
      setQuery(value);
    }
  };

  const column = crmColumn(setShowImageModal, handleConfirm, setPreviewImages);

  const Conversion = () => {
    return (
      <CsvDownload
        data={posts}
        filename="CRM.csv"
        style={{
          boxShadow: "inset 0px 1px 0px 0px #e184f3",
          backgroundColor: "#27AE60",
          borderRadius: "6px",
          display: "inline-block",
          cursor: "pointer",
          color: "#ffffff",
          fontSize: "15px",
          height: "40px",
          fontWeight: "bold",
          padding: "6px 24px",
          textDecoration: "none",
        }}
      >
        Export
      </CsvDownload>
    );
  };
  // const handleModals = (type) => {
  //   if(type ==='images'){
  //     setShowImageModal(true)
  //   }
  // };

  // const handlePreview = (e) => {
  //   const { files } = e.target;
  //   console.log(files)
  //   if (files.length > 10) {
  //     notification.warning('Oops!', `Upload exceeded limit of 10`, 'error');
  //     return;
  //   }
  //   setImages(files)
  // };

  return (
    <div className="w-100">
      <SearchBar
        page="crm"
        total={metaData}
        Conversion={Conversion}
        handleChange={handleChange}
        setFilterType={setFilterType}
        selectOptions={selectOptions}
        createButton={true}
        placeholder="Search by Username or Email"
      />
      <LoadingComponent loading={loading} />
      <div>
        <Table
          scroll={{ x: 800 }}
          columns={column}
          dataSource={posts}
          rowKey="id"
          loading={loading}
          pagination={false}
        />
        <Pagination
          total={total}
          responsive={true}
          defaultCurrent={1}
          onChange={(e) => setPages(e)}
          defaultPageSize={20}
          showSizeChanger={false}
        />
      </div>
      {imageModal &&
        previewImages.length &&
        (retrieveFileType(previewImages[0]?.imageURL) === "img" ? (
          <ImagePreview
            data={previewImages}
            visible={imageModal}
            setVisible={setShowImageModal}
          />
        ) : (
          <Modal
            visible={imageModal}
            onCancel={()=> setShowImageModal(false)}
            centered
            footer={false}
            title={<p className="text-center">Media Center.</p>}
            width={1000}
          >
            {retrieveFileType(previewImages[0]?.imageURL) === "video" ? (
              <video preload="metadata" autoPlay width="100%" controls>
                <source src={previewImages[0]?.imageURL} type="video/mp4" />
                <source src={previewImages[0]?.imageURL} type="video/ogg" />
              </video>
            ) : (
              "Invalid manage_donations"
            )}
          </Modal>
        ))}
    </div>
  );
};

export default CRM;
