const config = {
  file: "http://68.183.60.114:8059",
  baseUrl: "http://68.183.60.114:8059",
  baseLink: "http://157.230.223.54:2237/waya-withdrawal-service/api/v2",
  bLink: "http://157.230.223.54:2030/bvn-service/api",
  bills: "http://157.230.223.54:8181/billspay",
  // bills: "https://api.dev.waya-group.com/billspay",
  card: "http://157.230.223.54:3020",
  wallet: "http://157.230.223.54:9009",
  acct: "http://46.101.41.187:7090",
  forget: "http://46.101.41.187:8060",
  core: "http://46.101.41.187:9196",
  cat: process.env.REACT_APP_BASE_URL + "/wayagram-service/main",
  del: "https://curvy-grasshopper-53.loca.lt/all-posts",
  WAYA_PHONE: "2349063059889",
  postUrl: process.env.REACT_APP_BASE_URL + "/wayagram-service/all-posts",
  mainUrl: process.env.REACT_APP_BASE_URL + "/wayagram-service/main",
  graphUrl: process.env.REACT_APP_BASE_URL + "/wayagram-service/graph",
  disputeUrl: process.env.REACT_APP_BASE_URL + "/wayagram-service/dispute",
  notificationUrl:process.env.REACT_APP_BASE_URL + "/wayagram-service/notification",
  socialUrl:process.env.REACT_APP_BASE_URL + "/social-file-resource",
  roleUrl:process.env.REACT_APP_BASE_URL + "/wayagram-service/role-access",
  authUrl:process.env.REACT_APP_AUTH_URL + "/auth-service/api/v1/auth",
  bankAuthUrl:process.env.REACT_APP_AUTH_URL + "/auth-service/api/v1",
  contestUrl: process.env.REACT_APP_BASE_URL + "/contest-service/api/v1",
  referralUrl: process.env.REACT_APP_BASE_URL + "/referral-service/api/v1",
  gramUrl: process.env.REACT_APP_BASE_URL + "/wayagram-service/",




  // baseUrl: "https://admin.waya-ng.com/v1",
  // baseUrl: "https://api.dev.waya-group.com/", // v2 production API
  // baseUrl: "http://admin.waya-ng.com/v1", // v1 Production API
  // baseUrl: "http://142.93.37.0:4080/v1", // v1 Development API
  // baseUrl: "http://46.101.41.187:8059", // v2 Development API
  // baseUrl: "http://68.183.60.114:8059", // v2 Development API
  //http://68.183.60.114:8059
  // baseUrl: "http://68.183.60.114:8051",

  // payStackAPIKey: 'sk_live_b2b30b36aee5cd7b6f686db89b1e014489b70316',
};

export default config;

// http://157.230.223.54:8181/billspay/api/v1/config/aggregator/toggle/1
