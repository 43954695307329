import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../services/config";
import { Modal, Form, Button, notification, Upload, Input, Table, Space, Image } from "antd";
import { getRequest, postRequest, putRequest } from "../../services/request";
import { getUserData } from "../../services/helpers";
import AntDropdown from "../../components/dropdown/AntDropdown";
import useMatStyles from "../users/UserListView/WebPos/style";
import CsvDownload from "react-json-to-csv";
import StageSettings from "./contestSettings";

const ContestDetails = () => {
  const navigate = useNavigate();
  const styles = useMatStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [contestantsData, setContestantsData] = useState([]);
  const [preview, setPreviewImages] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [contestName, setContestName] = useState('');
  const [showUpdateSettings, setSShowUpdateSettings] = useState(false);
  const [stat, setStat] = useState({});
  const [exportData, setExportData] = useState([]);
  const [contestData, setContestData] = useState([]);

  const getContestants = async () => {
    let response;
      response = await getRequest(
        `contests/contestants?contestId=${id}`,
        config.contestUrl,
        true
      );
      const { data, status } = response?.data || [];
      const pen = [];
      const approved = [];
      const rejected = [];
      const filtered = [];
      if (status) {
        setContestantsData(data);
        data?.map((e) => {
          filtered.push({
            firstName: e?.firstName,
            lastName: e?.lastName,
            email: e?.email,
            PhoneNumber: e?.PhoneNumber,
            institution: e?.affiliationName,
            state: e?.state,
            voteCounts: e?.voteCounts,
            dateJoined: e?.createdAt,
            status: e?.status,
            contestPhoto: e?.miscellaneous?.contestPhoto[0],
            constestCoverPhoto: e?.miscellaneous?.coverPhoto[0],
            // contestIdcard: e?.miscellaneous?.idCard[0],
            contestCover: e?.miscellaneous?.coverVideo[0],
          });
        });
        setExportData(filtered);
        data?.map((e) => {
          if (e?.status === 'REJECTED')  rejected.push(e);
          if (e?.status === 'ACTIVE')  approved.push(e);
          if (e?.status === 'PENDING')  pen.push(e);
        })
        const sum = data.reduce((accumulator, object) => {
          return accumulator + Number(object.voteCounts);
        }, 0);
        setStat({
          all: data?.length,
          pending: pen?.length,
          approved: approved?.length,
          rejected: rejected?.length,
          voteCounts: sum,
        });
      }
      
      console.log({ stat });   
  };
  const getContest= async () => {
    let userData = await getUserData();
    console.log({userData})
    const resp= await getRequest(`get-user-data?user_id=${userData?.id}`, config.roleUrl);
    console.log({ resp})
    let response;
      response = await getRequest(
      `contests?profileId=${resp?.data?.data?.id}&id=${id}`,
        config.contestUrl,
        true
      );
      const { data, status } = response?.data || [];
      if (status) {
        setContestName(data[0]?.contest?.description);
        setContestData(data[0]?.contest);
        
      }    
  };
  
  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (_, record) => record?.firstName
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (_, record) => record?.lastName
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, record) => record?.email
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Institution',
      dataIndex: 'affiliationName',
      key: 'affiliationName',
      render: (_, record) => record?.affiliationName
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (_, record) => record?.state
    },
    {
      title: 'Contest Photo',
      dataIndex: 'contestPhoto',
      key: 'contestPhoto',
      render: (text, record) => <Image
      preview={{ visible: false }}
      alt="contest photo"
      onClick={() => {
        setPreviewImages(record?.miscellaneous?.contestPhoto);
        setShowImageModal(true);
      }}
      src={record?.miscellaneous?.contestPhoto[0]}
      width={50}
      height={50}
    />,
    },
    {
      title: 'Cover Photo',
      dataIndex: 'coverPhoto',
      key: 'coverPhoto',
      render: (text, record) => <Image
      preview={{ visible: false }}
      alt="cover photo"
      onClick={() => {
        setPreviewImages(record?.miscellaneous?.coverPhoto);
        setShowImageModal(true);
      }}
      src={record?.miscellaneous?.coverPhoto}
      width={50}
      height={50}
    />,
    },
    {
      title: 'ID Card',
      dataIndex: 'idCard',
      key: 'idCard',
      render: (text, record) => <Image
      preview={{ visible: false }}
      alt="idCard"
      onClick={() => {
        setPreviewImages(record?.miscellaneous?.idCard);
        setShowImageModal(true);
      }}
      src={record?.miscellaneous?.idCard}
      width={50}
      height={50}
    />,
    },
    {
      title: 'Cover Video',
      dataIndex: 'coverVideo',
      key: 'coverVideo',
      render: (text, record) =>
      <video width="50" height="50"
        onClick={() => {
          setPreviewImages(record?.miscellaneous?.coverVideo);
          setShowVideoModal(true);
        }}

      >
        <source
          src={record?.miscellaneous?.coverVideo}
      />
      </video>
      
    //   <Video
    //   preview={{ visible: false }}
    //   alt="coverVideo"
    //   onClick={() => {
    //     setPreviewImages(record?.miscellaneous?.coverVideo);
    //     setShowImageModal(true);
    //   }}
    //   src={record?.miscellaneous?.coverVideo}
    //   width={50}
    //   height={50}
    // />,
    },
    {
      title: 'No of Vote',
      dataIndex: 'voteCounts',
      key: 'voteCounts',
      render: (_, record) => record?.voteCounts
    },
    {
        title: 'Date Joined',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, record) => record?.createdAt
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => record?.status
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_, record) => (
    //     <div className="row">
    //       <AntDropdown
    //          dropDownOptions={['View more']}
    //          handleDropdownAction={(item) => handleDropdownAction(item, record)}
    //          isArrow
    //       />
    //       {/* <AntDrop
    //       <EditFilled />
    //       <Switch
    //         checked={record?.status === 'ACTIVE' ? true : false}
    //         onClick={() => handleActivateDeactivate(record?.profileId, record?.status === 'ACTIVE' ? 'DISABLE' : 'ENABLE')}
    //       /> */}
    //     </div>
    //   ),
    // },
  ];

  useEffect(() => {
    getContest();
    getContestants();
  }, []);
  return (
    <div className="w-100">
      <Modal
         visible={showImageModal}
          onCancel={() => {
            setShowImageModal(false)
          }}
          footer={false}
          centered
        >
          <div style={{ textAlign: 'center' }}>
              <Image
                  alt="contest Img"
                  src={preview}
                  width={200}
                  height={200}
              />
          </div>
        </Modal>
        <Modal
         visible={showVideoModal}
          onCancel={() => {
            setShowVideoModal(false)
          }}
          footer={false}
          centered
        >
          <div style={{ textAlign: 'center' }}>
        <video width="400" controls>
          <source
            src={preview}
        />
        </video>
          </div>
        </Modal>
      {/* <Button
        size="large"
        loading={loading}
        htmlType="submit"
        type="primary"
        className="rounded px-5"
        onClick={() => setSShowUpdateSettings(true)}
    >
        Create Settings
    </Button> */}
    <div>
      <div style={{ textAlign: 'end', marginBottom: 20 }}>
        <Button
          size="large"
          loading={loading}
          htmlType="submit"
          type="primary"
          className="rounded px-5 mx-2"
          onClick={() => setSShowUpdateSettings(true)}
        >
          Activate Contest Stage
        </Button>
        <CsvDownload
          data={exportData}
          filename={`${contestName}-contestant.csv`}
          style={{
          background: '#ff6700',
          color: '#000',
          fontSize: '15px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
         
        }}
      >
        Export contestants
      </CsvDownload>
      </div>
      <h3 className="text-center">
        {contestName?.toUpperCase()}
      </h3>
      <div className={styles.dashboardItem}>
        <div className={styles.dashItem}>
          <div className={styles.diTop}>Total Number of Registered Contestants</div>
          <div className={styles.dibot}>{stat?.all}</div>
        </div>
        <div className={styles.dashItem}>
          <div className={styles.diTop}>Total Number of Accepted Contestants</div>
          <div className={styles.dibot}>{stat?.approved}</div>
        </div>
        <div className={styles.dashItem}>
          <div className={styles.diTop}>Total Number of Rejected Contestants</div>
          <div className={styles.dibot}>{stat?.rejected}</div>
        </div>
        <div className={styles.dashItem}>
          <div className={styles.diTop}>Total Number of Vote Count</div>
          <div className={styles.dibot}>{stat?.voteCounts}</div>
        </div>        
      </div>
    <Table columns={columns} dataSource={contestantsData} />
    </div>
      {showUpdateSettings ?
      <StageSettings
        record={contestData}
        showModal={showUpdateSettings}
        hideModal={setSShowUpdateSettings}
        getContest={getContest}
        />
      : ''}

    </div>
  );
};

export default ContestDetails;
