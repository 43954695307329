import React, { useEffect, useState } from "react";
import { useNavigate  } from 'react-router-dom';
// import {
//   FaShoppingBag,
//   IconOverview,
//   IconPromotions,
//   IconQr,
//   IconSubscription,
//   IconTransaction,
//   IconUsers,
// } from '../../assets/icons';
import IconSettings from '../../assets/icons/icon-settings';
import { default as SLUGS} from '../../resources/slugs';
import { convertSlugToUrl } from '../../resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';
import { BsFillCaretDownFill } from 'react-icons/bs'
import {
  getRequest,
  // deleteRequest,
  // postRequest,
  // patchRequest,
} from "../../services/request";
import config from "../../services/config";
import { getUserData } from "../../services/helpers";
import { SiSimpleanalytics, SiPagekit, SiGoogleclassroom, SiMomenteo } from "react-icons/si";
// import { FaPoll } from "react-icons/cg"
// import { FaMoneyBillAlt } from 'react-icons/gr'
import {TiPointOfInterestOutline} from 'react-icons/ti'
import {MdGroups, MdPriceChange, MdOutlineRequestPage, 
  MdOutlineMarkEmailRead, MdOutlineCircleNotifications, MdSms, MdPermMedia, MdOutlineDynamicFeed} from 'react-icons/md'
import {FaHashtag, FaUsers, FaMoneyBillAlt, FaPoll, FaMoneyBill, FaAtom, FaShoppingBag} from 'react-icons/fa'
import {RiFunctionLine} from 'react-icons/ri'
// import {FaAtom} from 'react-icons/io5'
import{VscReport, VscFeedback} from 'react-icons/vsc'
import {RiAdminLine} from 'react-icons/ri'
import {GiSellCard} from 'react-icons/gi'






function SidebarComponent() {
  const push = useNavigate ();

  const isMobile = window.innerWidth <= 768;
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  function onClick(slug, parameters = {}) {
    push(convertSlugToUrl(slug, parameters));
  }

  const getAccess = async () => {
    let userData = getUserData();
    setLoading(true);
    const response = await getRequest(`get-user-data?user_id=${userData?.id}`, config.roleUrl);

    setLoading(false);
    if (response?.data?.status == false) {
      return;
    }
    const data = response?.data?.data || [];
    setRoles(data?.Role?.RoleAccess?.map((item, idx) => item?.Permission?.access) || []);
  };

  useEffect(() => {
    getAccess();
  }, []);

  return (
    <Menu isMobile={isMobile}>
      <div style={{ paddingTop: 18, paddingBottom: 30 }}>
        <LogoComponent isColored={true} />
      </div>
      <MenuItem
        id={SLUGS.waya_analytics}
        title="WayaGram Analytics"
        onClick={() => onClick(SLUGS.waya_analytics)}
        //hidden={!roles.includes('CAN_VIEW_ANALYTICS' || 'CAN_MANAGE_ANALYTICS')}
        icon={SiSimpleanalytics}
      />
      <MenuItem
        id={SLUGS.manage_posts}
        title="Manage Posts"
        onClick={() => onClick(SLUGS.manage_posts)}
        hidden={!roles.includes('CAN_VIEW_POSTS')}
        icon={MdOutlineDynamicFeed}
      />
      {/*  || 'CAN_MANAGE_POSTS' || 'CAN_MANAGE_POLLS' */}
      <MenuItem
        id={SLUGS.manage_polls}
        title="Manage Polls"
        onClick={() => onClick(SLUGS.manage_polls)}
        hidden={!roles.includes('CAN_VIEW_POLLS')}
        icon={FaPoll}
      />
      <MenuItem
        id={SLUGS.product}
        title="Products"
        rightIcon={BsFillCaretDownFill}
        items={[
          SLUGS.product,
          SLUGS.categories,
          SLUGS.flyers,
          SLUGS.orders,
          
        ]}
        icon={GiSellCard}
      >
        <MenuItem
          id={SLUGS.product}
          title="Manage Products"
          level={2}
          onClick={() => onClick(SLUGS.product)}
          hidden={!roles.includes('CAN_VIEW_PRODUCTS' || 'CAN_MANAGE_PRODUCTS')}
          icon={GiSellCard}
        />
        <MenuItem
          id={SLUGS.categories}
          title="Categories"
          level={2}
          onClick={() => onClick(SLUGS.categories)}
          hidden={!roles.includes('CAN_VIEW_PRODUCTS' || 'CAN_MANAGE_PRODUCTS')}
          icon={MdOutlineDynamicFeed}
        />
         {/* <MenuItem
          id={SLUGS.orders}
          title="Products orders"
          level={2}
          onClick={() => onClick(SLUGS.orders)}
          hidden={!roles.includes('CAN_VIEW_PRODUCTS' || 'CAN_MANAGE_PRODUCTS')}
          icon={MdOutlineDynamicFeed}
        /> */}
        <MenuItem
          id={SLUGS.flyers}
          title="Flyers"
          level={2}
          onClick={() => onClick(SLUGS.flyers)}
          hidden={!roles.includes('CAN_VIEW_PRODUCTS' || 'CAN_MANAGE_PRODUCTS')}
          icon={MdOutlineDynamicFeed}
        />
      </MenuItem>
      
      <MenuItem
        id={SLUGS.manage_donations}
        title="Donations"
        onClick={() => onClick(SLUGS.manage_donations)}
        hidden={!roles.includes('CAN_VIEW_DONATION' || 'CAN_MANAGE_DONATION')}
        icon={FaMoneyBillAlt}
      />
      <MenuItem
        id={SLUGS.manage_moments}
        title="Manage Moments"
        onClick={() => onClick(SLUGS.manage_moments)}
        hidden={!roles.includes('CAN_VIEW_MOMENTS' || 'CAN_MANAGE_MOMENTS')}
        icon={SiMomenteo}
      />
      <MenuItem
        id={SLUGS.manage_interests}
        title="Manage Interests"
        onClick={() => onClick(SLUGS.manage_interests)}
        hidden={!roles.includes('CAN_VIEW_INTEREST' || 'CAN_MANAGE_INTEREST')}
        icon={TiPointOfInterestOutline}
      />
      <MenuItem
        id={SLUGS.hashtag}
        title="Hashtags"
        onClick={() => onClick(SLUGS.hashtag)}
        hidden={!roles.includes('CAN_VIEW_HASHTAG' || 'CAN_MANAGE_HASHTAG')}
        icon={FaHashtag}
      />
      <MenuItem
        id={SLUGS.group_accounts}
        title="Group Accounts"
        onClick={() => onClick(SLUGS.group_accounts)}
        hidden={!roles.includes('CAN_VIEW_GROUP' || 'CAN_MANAGE_GROUP')}
        icon={MdGroups}
      />
      <MenuItem
        id={SLUGS.manage_events}
        title="Manage Event"
        onClick={() => onClick(SLUGS.manage_events)}
        hidden={!roles.includes('CAN_VIEW_EVENTS' || 'CAN_MANAGE_EVENTS')}
        icon={RiFunctionLine}
      />
      <MenuItem
        id={SLUGS.manage_pages}
        title="Manage Pages"
        onClick={() => onClick(SLUGS.manage_pages)}
        hidden={!roles.includes('CAN_VIEW_PAGE' || 'CAN_MANAGE_PAGE')}
        icon={SiPagekit}
      />
      <MenuItem
        id={SLUGS.waya_users}
        title="Wayagram Users"
        onClick={() => onClick(SLUGS.waya_users)}
        hidden={!roles.includes('CAN_VIEW_USER' || 'CAN_MANAGE_USER')}
        icon={FaUsers}
      />
      <MenuItem
        id={SLUGS.waya_simulated_users}
        title="Simulations"
        onClick={() => onClick(SLUGS.waya_simulated_users)}
        hidden={!roles.includes('CAN_VIEW_SIMULATION' || 'CAN_MANAGE_SIMULATION')}
      />
      <MenuItem
        id={SLUGS.manage_pricing}
        title="Manage Pricing"
        onClick={() => onClick(SLUGS.manage_pricing)}
        items={[
          SLUGS.manage_pricing,
          SLUGS.CRM, 
        ]}
        icon={MdPriceChange}
      >
        <MenuItem
          id={SLUGS.manage_pricing}
          title="Pricings"
          level={2}
          onClick={() => onClick(SLUGS.manage_pricing)}
          hidden={!roles.includes('CAN_VIEW_PRICING' || 'CAN_MANAGE_PRICING')}
          icon={MdPriceChange}

        />
      </MenuItem>
      <MenuItem
        id={SLUGS.manage_promotion}
        title="Manage Promotion"
        onClick={() => onClick(SLUGS.manage_promotion)}
        hidden={!roles.includes('CAN_VIEW_PROMOTION_PRICING' || 'CAN_MANAGE_PROMOTION_PRICING')}
        icon={FaShoppingBag}
      />
      {/* <MenuItem
        id={SLUGS.manage_referrals}
        title="Manage Referrals"
        onClick={() => onClick(SLUGS.manage_referrals)}
        hidden={!roles.includes('CAN_VIEW_USER' || 'CAN_MANAGE_USER')}
        icon={FaUsers}
      /> */}
      <MenuItem
        id={SLUGS.logs}
        title="Logs"
        rightIcon={BsFillCaretDownFill}
        items={[
          SLUGS.logs,
          SLUGS.dispute,
          SLUGS.feedback,
          SLUGS.account,
          SLUGS.request,
        ]}
        icon={FaAtom}
      >
        <MenuItem
          id={SLUGS.logs}
          title="Payment Log"
          level={2}
          onClick={() => onClick(SLUGS.logs)}
          hidden={!roles.includes('CAN_VIEW_PAYMENTS_REQUEST' || 'CAN_MANAGE_PAYMENTS_REQUEST')}
          icon={MdOutlineRequestPage}
        />
        <MenuItem
          id={SLUGS.report_log}
          title="Report Log"
          level={2}
          onClick={() => onClick(SLUGS.report_log)}
          hidden={!roles.includes('CAN_VIEW_REPORT' || 'CAN_MANAGE_REPORT')}
          icon={VscReport}
        />
        <MenuItem
          id={SLUGS.disbursement}
          title="Disbursement Log"
          level={2}
          onClick={() => onClick(SLUGS.disbursement)}
          hidden={!roles.includes('CAN_VIEW_PAYMENTS_REQUEST' || 'CAN_MANAGE_PAYMENTS_REQUEST')}
          icon={MdOutlineRequestPage}
        />
        <MenuItem
          id={SLUGS.request_log}
          title="Request Log"
          level={2}
          onClick={() => onClick(SLUGS.request_log)}
          hidden={!roles.includes('CAN_VIEW_REPORT' || 'CAN_MANAGE_REPORT')}
          icon={VscReport}
        />
        <MenuItem
          id={SLUGS.feedback}
          title="Feedback"
          level={2}
          onClick={() => onClick(SLUGS.feedback)}
          hidden={!roles.includes('CAN_VIEW_FEEDBACK' || 'CAN_MANAGE_FEEDBACK')}
          icon={VscFeedback}
        />
        <MenuItem
          id={SLUGS.account}
          title="Account"
          level={2}
          onClick={() => onClick(SLUGS.account)}
          hidden={!roles.includes('CAN_APPROVE_PAYMENT_REQUEST' || 'CAN_DISBURSE_MONEY')}
          icon={FaMoneyBill}
        />
        {/* <MenuItem
          id={SLUGS.waya_account}
          title="Waya Account"
          level={2}
          onClick={() => onClick(SLUGS.waya_account)}
        /> */}
        <MenuItem
          id={SLUGS.activity_log}
          title="Activity Log"
          level={2}
          onClick={() => onClick(SLUGS.activity_log)}
          hidden={!roles.includes('CAN_VIEW_ACTIVITY_LOG' || 'CAN_MANAGE_ACTIVITY_LOG')}
          icon={SiGoogleclassroom}
        />
      </MenuItem>
      <MenuItem
        id={SLUGS.manage_hosts}
        title="Contest"
        rightIcon={BsFillCaretDownFill}
        items={[
          SLUGS.manage_hosts,
          SLUGS.manage_contests,
          SLUGS.contest_stats,
        ]}
        icon={FaPoll}
      >
        <MenuItem
          id={SLUGS.manage_contests}
          title="Manage Contests"
          level={2}
          onClick={() => onClick(SLUGS.manage_contests)}
          hidden={!roles.includes('CAN_VIEW_REPORT' || 'CAN_MANAGE_REPORT')}
          icon={MdOutlineDynamicFeed}
        />
        <MenuItem
          id={SLUGS.manage_hosts}
          title="Manage Hosts"
          level={2}
          onClick={() => onClick(SLUGS.manage_hosts)}
          hidden={!roles.includes('CAN_VIEW_REPORT' || 'CAN_MANAGE_REPORT')}
          icon={FaUsers}
        />
        <MenuItem
          id={SLUGS.contest_stats}
          title="Contests Statistics"
          level={2}
          onClick={() => onClick(SLUGS.contest_stats)}
          hidden={!roles.includes('CAN_VIEW_REPORT' || 'CAN_MANAGE_REPORT')}
          icon={SiSimpleanalytics}
        />
      </MenuItem>
      <MenuItem
        id={SLUGS.email_management}
        title="Email Management"
        onClick={() => onClick(SLUGS.email_management)}
        hidden={!roles.includes('CAN_VIEW_EMAIL_NOTIFICATION' || 'CAN_SEND_EMAIL_NOTIFICATION')}
        icon={MdOutlineMarkEmailRead}
      />
      <MenuItem
        id={SLUGS.push_notifications}
        title="Push Notification"
        onClick={() => onClick(SLUGS.push_notifications)}
        hidden={!roles.includes('CAN_VIEW_NOTIFICATION' || 'CAN_SEND_NOTIFICATION')}
        icon={MdOutlineCircleNotifications}
      />
      <MenuItem
        id={SLUGS.sms_management}
        title="Sms Management"
        onClick={() => onClick(SLUGS.sms_management)}
        hidden={!roles.includes('CAN_SEND_SMS_NOTIFICATION' || 'CAN_VIEW_SMS_NOTIFICATION')}
        icon={MdSms}
      />
      <MenuItem
        id={SLUGS.CRM}
        title="CRM"
        level={2}
        onClick={() => onClick(SLUGS.CRM)}
        hidden={!roles.includes('CAN_VIEW_CRM' || 'CAN_MANAGE_CRM')}
        icon={MdPermMedia}
      />
      <MenuItem
        id={SLUGS.CV}
        title="Content Visibility"
        level={2}
        onClick={() => onClick(SLUGS.CV)}
        hidden={!roles.includes('CAN_VIEW_CRM' || 'CAN_MANAGE_CRM')}
        icon={MdPermMedia}
      />
      <MenuItem
        id={SLUGS.manage_admin}
        title="Manage Admin"
        level={2}
        onClick={() => onClick(SLUGS.manage_admin)}
        hidden={!roles.includes('CAN_VIEW_ADMIN' || 'CAN_MANAGE_ADMIN')}
        icon={RiAdminLine}
      />
      <MenuItem
        id={SLUGS.business_report}
        title="Business Report"
        rightIcon={BsFillCaretDownFill}
        items={[
          SLUGS.business_report,
          SLUGS.groups,
          SLUGS.pages,
          SLUGS.donations,
          SLUGS.polls,
          SLUGS.events,
          SLUGS.payment,
          SLUGS.payment_disburment,
          SLUGS.request,
          SLUGS.sms,
          SLUGS.email,
          SLUGS.push,
        ]}
        icon={FaAtom}
      >
        <MenuItem
          id={SLUGS.groups}
          title="Group Reporting"
          level={2}
          onClick={() => onClick(SLUGS.groups)}
          icon={MdGroups}
        />
        <MenuItem
          id={SLUGS.pages}
          title="Page Reporting"
          level={2}
          onClick={() => onClick(SLUGS.pages)}
          icon={SiPagekit}
        />
        <MenuItem
          id={SLUGS.donations}
          title="Donation Reporting"
          level={2}
          onClick={() => onClick(SLUGS.donations)}
          icon={FaMoneyBillAlt}
        />
        <MenuItem
          id={SLUGS.polls}
          title="Polls Reporting"
          level={2}
          onClick={() => onClick(SLUGS.polls)}
          icon={FaPoll}
        />
        <MenuItem
          id={SLUGS.events}
          title="Event Reporting"
          level={2}
          onClick={() => onClick(SLUGS.events)}
          icon={RiFunctionLine}
        />
        <MenuItem
          id={SLUGS.payment_request}
          title="Payment Reporting"
          level={2}
          onClick={() => onClick(SLUGS.payment_request)}
          icon={MdOutlineRequestPage}
        />
        <MenuItem
          id={SLUGS.payment_disburment}
          title="Disburse Reporting"
          level={2}
          onClick={() => onClick(SLUGS.payment_disburment)}
          icon={FaMoneyBill}
        />
        <MenuItem
          id={SLUGS.request}
          title="Request Reporting"
          level={2}
          onClick={() => onClick(SLUGS.request)}
          icon={VscReport}
        />
        <MenuItem
          id={SLUGS.sms}
          title="SMS Reporting"
          level={2}
          onClick={() => onClick(SLUGS.sms)}
          icon={MdSms}
        />
        <MenuItem
          id={SLUGS.email}
          title="Email Reporting"
          level={2}
          onClick={() => onClick(SLUGS.email)}
          icon={MdOutlineMarkEmailRead}
        />
        <MenuItem
          id={SLUGS.push}
          title="Notification Reporting"
          level={2}
          onClick={() => onClick(SLUGS.push)}
          icon={MdOutlineCircleNotifications}
        />
      </MenuItem>
    </Menu>
  );
}

export default SidebarComponent;
